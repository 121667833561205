<template>
    <div class="container">
        <div class="row">
            <div class="pagetitle titre">
                <nav style="--bs-breadcrumb-divider: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E')" aria-label="breadcrumb">
                    <ol class="breadcrumb m-3">
                        <li class="breadcrumb-item"><router-link to="/dashboard/liste_evenement">Liste evènemenet</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page"><router-link to="/dashboard/liste_invite2">Liste invité</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Invité confirmé</li>
                    </ol>
                </nav>

                <h1 class="m-3" style="font-size: 24px; margin-bottom: 0; font-weight: 600; color: #034101">Invités confirmés</h1>
                <hr class="border-bottom" />
                <div class="row mt-2">
                    <div class="user mb-5">
                        <div class="col-md-12 m-2">
                            <p class="fs-5 float-start" style="font-weight: bold">Invités ayant confirmé leur présence</p>
                        </div>
                        <br />

                        <div class="row mt-4">
                            <div class="card-body table-responsive">
                                <table class="table table-hover table-striped table-borderless datatable">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">N</th>
                                            <th scope="col">Code Invité</th>
                                            <th scope="col">Identité</th>
                                            <th scope="col">Tel</th>
                                            <th scope="col">Liste d'invité</th>
                                            <th scope="col">Date de création</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(invite, index) in invites" :key="index">
                                            <th scope="row">{{ index + 1 }}</th>
                                            <td>{{ invite.code }}</td>
                                            <td>{{ invite.nom_invite }}</td>
                                            <td>{{ invite.telephone_invite }}</td>
                                            <td>{{ invite.nom_liste_invite }}</td>
                                            <td>{{ formatDateString(invite.created_at) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-if="!loadingTable && invites === undefined " class="text-center mt-5">
                                    <!-- Loader/spinner code goes here -->
                                    <p>Pas de données disponible...</p>
                                </div>
                                <div v-if="loadingTable" class="text-center mt-5">
                                    <!-- Loader/spinner code goes here -->
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <p>chargement...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useRoute } from "vue-router";
    import axiosInstance from "../api/axios";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    import { Modal } from "bootstrap/dist/js/bootstrap.js";

    export default {
        components: {},
        data() {
            return {
                isDropdownOpen: false,
                loadingTable: false,
                idEvenement: "",
                listeInviteId: "",
                invites: [],
                vide: false,
            };
        },
        mounted() {
            const route = useRoute();
            this.idEvenement = route.params.idEvenement;
            this.listeInviteId = route.params.idListe_invite;
            this.afficherliste();
        },

        methods: {
            formatDateString(dateString) {
                // Convertit la chaîne de date en un objet Date
                const date = new Date(dateString);

                // Obtient les composants de la date
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");

                // Retourne la date formatée
                return `${year}-${month}-${day}`;
            },
            toggleDropdown() {
                this.isDropdownOpen = !this.isDropdownOpen;
            },
            afficherliste() {
                this.loadingTable = true;
                axiosInstance
                    .get(`api/evenements/${this.idEvenement}/listeinvites/${this.listeInviteId}/invitations/confirm_presence`)
                    .then((response) => {
                        this.loadingTable = false;
                        this.invites = response.data.liste;
                        console.log(response.data);
                    })
                    .catch((error) => {
                        this.loadingTable = false;
                        console.error("Erreur lors de la requête GET pour les listes :", error);
                        if (error.response) {
                            console.error("Réponse du serveur :", error.response.data);
                        }

                        this.loadingTable = false;
                    });
            },

            showToastWarning(message) {
                toast.warning(message, {
                    autoClose: 3000,
                });
            },
            showToastSuccess(message) {
                toast.success(message, {
                    autoClose: 3000,
                });
            },
            showToastInfo(message) {
                toast.info(message, {
                    autoClose: 3000,
                });
            },
        },
    };
</script>

<style scoped>
    .pagetitle {
        margin-top: 15px;
        margin-bottom: 10px;
        background-color: white;
        padding: 10px;
        padding-bottom: 1px;
    }

    .pagetitle a {
        color: #025202a9;
        text-decoration: none;
    }

    .liste {
        margin-top: 20px;
        width: 103%;
        background-color: white;
        margin-right: 50px;
    }
    .para0 {
        font-weight: bold;
        margin-bottom: 1px;
    }

    .user {
        border: none;
    }

    /* Style de base du bouton du dropdown */
    .dropbtn {
        color: rgba(1, 87, 1, 0.945);

        cursor: pointer;
    }

    /* Style du dropdown au survol */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Style des options du dropdown */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 150px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        padding: 9px;
        text-decoration: none;
        display: block;
        color: #333;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }
    .btn1 {
        color: rgba(1, 87, 1, 0.945);
        cursor: pointer;
    }
    .btn2 {
        color: red;
        cursor: pointer;
    }

    .card {
        border: none;
        border-radius: 5px;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    }
    .card-title {
        padding: 20px;
        font-weight: bold;
    }
    .card-title h5 {
        font-size: 20px;
        color: #000918;
    }
    .card-body {
        padding: 0 20px 20px 20px;
    }
    .obligatoire {
        color: #034101;
    }

    .ajout {
        background-color: #013f04;
        color: white;
    }

    .ajout:hover {
        color: #013f04;
        text-decoration: none;
        background-color: #013f04;
    }

    #main {
        margin-top: 20px;
        padding: px;
        transition: all 0.3s;
    }

    @media (max-width: 1199px) {
        #main {
            padding: 10px;
        }
    }
</style>
