<template>
    <div class="min-vh-100 d-flex align-items-center position-relative justify-content-center background">
        <svg width="64px" height="96px" class="svg position-absolute start-50 translate-middle-x" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- Contenu de votre SVG -->
        </svg>

        <div class="backdrop-blur-sm bg-white shadow-lg p-4 rounded box-border connexion">
            <div>
                <h4 style="text-align: center; color: #013f04">Réinitialisation du Mot de passe</h4>
            </div>
            <form @submit.prevent="resetPassword">
                <div class="mb-4">
                    <label for="email" class="form-label">E-mail<span class="text-success">*</span></label>
                    <input type="email" class="form-control" required id="email" name="email" v-model="email" placeholder="dodo@gmail.com" />
                </div>
                <div class="mb-4">
                    <label for="password" class="form-label">Nouveau mot de passe<span class="text-success">*</span></label>
                    <input type="password" class="form-control" id="password" v-model="password" placeholder="8 caractères minimum" />
                    <span v-if="!isPasswordValid" class="text-danger">Le mot de passe doit contenir au moins 8 caractères.</span>
                </div>
                <div class="mb-4">
                    <label for="confirmPassword">Confirmer le mot de passe<span class="text-success">*</span></label>
                    <input type="password" class="form-control" id="confirmPassword" v-model="confirmPassword" placeholder="8 caractères minimum" />
                    <span v-if="password !== confirmPassword" class="text-danger">Les mots de passe ne correspondent pas.</span>
                </div>
                <div class="d-flex justify-content-between">
                    <button type="submit" class="btn d-flex justify-content-center align-items-center mb-4" :disabled="isSubmitDisabled" style="background-color: #013f04; color: white">
                        <span v-if="isLoading">Chargement..</span>
                        <span v-else> Réinitialiser</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";
import "vue3-toastify/dist/index.css";

export default {
    data() {
        return {
            email: "",
            tokenReset: "",
            password: "",
            confirmPassword: "",
            isLoading: false,
        };
    },
    mounted() {
        const route = useRoute();
        this.email = route.params.email;
        this.tokenReset = route.params.tokenReset;

        console.log(route.params.tokenReset);
        // Faites ce que vous voulez avec les valeurs email et token
    },
    computed: {
        isPasswordValid() {
            // Vérifiez que le mot de passe a au moins 8 caractères
            return this.password.length >= 8;
        },
        isSubmitDisabled() {
            // Désactive le bouton de soumission si les mots de passe ne sont pas valides
            return !this.isPasswordValid || this.password !== this.confirmPassword;
        },
    },
    methods: {
        resetPassword() {
            if (this.password !== this.confirmPassword) {
                toast.warning("Les mots de passe ne correspondent pas.");
                return;
            }

            this.isLoading = true;

            const resetData = {
                password: this.password,
                password_confirmation: this.confirmPassword,
                tokenReset: this.tokenReset,
                email: this.email,
            };

            console.log(resetData);

            axiosInstance
                .post("api/resetPassword", resetData)
                .then((response) => {
                    toast.success("Mot de passe réinitialisé avec succès.");
                    this.$router.push("/");
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Une erreur s'est produite lors de la réinitialisation du mot de passe.");
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
