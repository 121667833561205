<template>
    <main id="main" class="main body">
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='%236c757d'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Autres Listes</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Invités Déclinés</li>
                </ol>
            </nav>
            <h1>Invités</h1>
            <hr class="border-bottom" />

            <section class="section">
                <div class="card">
                    <div class="row g-3 card-title">
                        <div class="col-4"><h5>Invités ayant décliné l'invitation</h5></div>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-hover table-striped table-borderless datatable">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">N</th>
                                    <th scope="col">Code Invité</th>
                                    <th scope="col">Identité</th>
                                    <th scope="col">Tel</th>
                                    <th scope="col">Liste d'invité</th>
                                    <th scope="col">Date de création</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(invite, index) in invites" :key="index">
                                    <th scope="row">{{ index + 1 }}</th>
                                    <td>{{ invite.code }}</td>
                                    <td>{{ invite.nom_invite }}</td>
                                    <td>{{ invite.telephone_invite }}</td>
                                    <td>{{ invite.nom_liste_invite }}</td>
                                    <td>{{ formatDateString(invite.created_at) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="!loadingTable && invites === undefined" class="text-center mt-5">
                            <!-- Loader/spinner code goes here -->
                            <p>Pas de données disponible...</p>
                        </div>
                        <div v-if="loadingTable" class="text-center mt-5">
                            <!-- Loader/spinner code goes here -->
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <p>chargement...</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
    import { useRoute } from "vue-router";
    import axiosInstance from "../api/axios";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    import { Modal } from "bootstrap/dist/js/bootstrap.js";

    export default {
        components: {},
        data() {
            return {
                isDropdownOpen: false,
                loadingTable: false,
                idEvenement: "",
                listeInviteId: "",
                invites: [],
            };
        },
        mounted() {
            const route = useRoute();
            this.idEvenement = route.params.idEvenement;
            this.listeInviteId = route.params.idListe_invite;
            this.afficherliste();
        },

        methods: {
            formatDateString(dateString) {
                // Convertit la chaîne de date en un objet Date
                const date = new Date(dateString);

                // Obtient les composants de la date
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");

                // Retourne la date formatée
                return `${year}-${month}-${day}`;
            },
            toggleDropdown() {
                this.isDropdownOpen = !this.isDropdownOpen;
            },
            afficherliste() {
                this.loadingTable = true;
                axiosInstance
                    .get(`api/evenements/${this.idEvenement}/listeinvites/${this.listeInviteId}/invitations/decline_presence`)
                    .then((response) => {
                        this.loadingTable = false;
                        if (response.data.status === 200) {
                            // Assurez-vous que la clé "liste" correspond à la structure réelle de votre réponse
                            this.invites = response.data.liste;
                        }
                    })
                    .catch((error) => {
                        this.loadingTable = false;
                        console.error("Erreur lors de la requête GET pour les listes :", error);
                        if (error.response) {
                            console.error("Réponse du serveur :", error.response.data);
                        }

                        this.loadingTable = false;
                    });
            },

            showToastWarning(message) {
                toast.warning(message, {
                    autoClose: 3000,
                });
            },
            showToastSuccess(message) {
                toast.success(message, {
                    autoClose: 3000,
                });
            },
            showToastInfo(message) {
                toast.info(message, {
                    autoClose: 3000,
                });
            },
        },
    };
</script>

<style scoped>
    body {
        background-color: #f6f9ff;
        font-size: 17px;
    }

    a {
        color: #013f04;
        text-decoration: none;
    }

    .carde:hover {
        color: white;
        text-decoration: none;
        background-color: #013f04;
    }

    #main {
        margin-top: 20px;
        padding: px;
        transition: all 0.3s;
    }

    @media (max-width: 1199px) {
        #main {
            padding: 10px;
        }
    }
    .pagetitle {
        margin-bottom: 10px;
        background-color: white;
        padding: 10px;
        padding-bottom: 1px;
    }

    .pagetitle h1 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 600;
        color: #034101;
    }
    .pagetitle a {
        color: #025202a9;
    }
    .card {
        border: none;
    }
    .card-title {
        padding: 20px;
        font-weight: bold;
    }
    .card-title h5 {
        font-size: 20px;
        color: #000918;
    }
    .card-body {
        padding: 0 20px 20px 20px;
    }
    .obligatoire {
        color: #034101;
    }
</style>
