<template>
    <main id="main" class="main body">
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='%236c757d'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Type évènement</a></li>
                    <li class="breadcrumb-item active" aria-current="page">liste</li>
                </ol>
            </nav>
            <h1>Type Evènement</h1>
            <hr class="border-bottom" />
            <section class="section">
                <div class="card">
                    <div class="row g-3 card-title">
                        <div class="col-4"><h5>Les types d'évènements</h5></div> 
                        <div class="col-7"></div>
                        <div class="col-1">
                            <button type="button" class="btn btn-success float-end" data-bs-toggle="modal" data-bs-target="#addTypeEvenement">Ajouter</button>
                        </div>
                        <div class="modal fade" ref="modalRef" id="addTypeEvenement" tabindex="-1">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Ajouter un nouveau type d'événement</h5>
                                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                                        <button type="button" class="btn-close" aria-label="Close" @click="fermerModal()"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form @submit.prevent="ajouterTypeEvenement" class="row g-3">
                                            <div class="col-12">
                                                <label for="libelle" class="form-label">Libellé <span class="obligatoire">*</span></label>
                                                <div class="form-floating">
                                                    <input v-model="nouveauTypeLibelle" type="text" class="form-control" name="nouveauTypeLibelle" id="libelle" required />
                                                    <label for="libelle">Libellé</label>
                                                </div>
                                            </div>
                                            <button v-if="loaderAdd" class="btn" style="background-color: #019d0b; color: white" type="button">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                chargement...
                                            </button>
                                            <button v-else type="submit" class="btn btn-success">Ajouter évènement</button>
                                        </form>
                                    </div>
                                    <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                  </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-hover table-striped table-borderless datatable">
                            <thead class="thead-light">
                                <tr class="justify-content-between">
                                    <th scope="col">N°</th>
                                    <th scope="col">Libelle</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Affiche la liste des types d'événements -->
                                <tr v-for="(item, index) in typesEvenements" :key="item.id" class="justify-content-between">
                                    <th scope="row">{{ index + 1 }}</th>
                                    <td>{{ item.libelle }}</td>
                                    <td class="text-end">
                                        <a @click="ouvrirConfirmationSuppression(item)" class="m-1" data-bs-toggle="modal" data-bs-target="#supinvite" style="padding: 0px 5px; color: white">
                                            <i class="bi bi-trash-fill text-danger"></i>
                                        </a>
                                        <a @click="ouvrirModalEdition(item)" class="m-1" data-bs-toggle="modal" data-bs-target="#editTypeEvenement" style="color: #019d0b">
                                            <i class="bi bi-pencil-square"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="modal fade" id="supinvite" tabindex="-1">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Suppression</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Ëtes-vous sûr de vouloir supprimer ces données ???</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        <button v-if="loaderDelete" class="btn btn-danger" style="color: white" type="button">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            chargement...
                                        </button>
                                        <button v-else @click="supprimerTypeEvenementConfirm" style="color: white" type="button" class="btn btn-danger">Supprimer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="editTypeEvenement" tabindex="-1">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Editer type évènement</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form @submit.prevent="mettreAJourTypeEvenement" class="row g-3">
                                            <div class="col-12">
                                                <label for="libelle" class="form-label">Libellé <span class="obligatoire">*</span></label>
                                                <div class="form-floating">
                                                    <input v-model="typeEvenementEnEdition.libelle" type="text" class="form-control" name="nouveauTypeLibelle" id="nouveauTypeLibelle" required />
                                                    <label for="libelle">Libellé</label>
                                                </div>
                                            </div>
                                            <button v-if="loaderPut" class="btn" style="background-color: #019d0b; color: white" type="button">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                chargement...
                                            </button>
                                            <button v-else type="submit" class="btn" style="padding: 7px 10px; background-color: #019d0b; color: white">Mettre à jour</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
import axiosInstance from "@/api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Modal } from "bootstrap/dist/js/bootstrap.js";

export default {
    data() {
        return {
             accessToken: null,
            nouveauTypeLibelle: "", // Modèle de données pour le champ de libellé
            typesEvenements: null,
            typeEvenementEnEdition: {
                id: null,
                libelle: "",
            },
            userId: null,
            confirmationSuppressionVisible: false,
            modalRef: null,
            loaderPut: false,
            loaderAdd: false,
            loaderDelete: false,
        };
    },
    methods: {
        showToatWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToatSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToatInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },
        fermerModal(id) {
            var myModalEl = document.getElementById(`${id}`);
            var modal = Modal.getInstance(myModalEl);
            modal.hide();
            console.log(id);
        },

        ajouterTypeEvenement() {
            this.loaderAdd = true;
            const userData = {
                nouveauTypeLibelle: this.nouveauTypeLibelle,
                userId: this.userId,
            };

             const config = {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`,
                },
            };

            axiosInstance
                .post("api/typeevenements", userData, config)
                .then((response) => {
                    this.loaderAdd = false;
                    this.showToatSuccess(response.data.msg);
                    this.fermerModal("addTypeEvenement");
                    if (response.data && response.data.success) {
                        console.log("Ajout réussi:", response.data);

                        // Ajoutez le nouvel élément à votre tableau typesEvenements
                        this.typesEvenements.push({ id: response.data.id, libelle: this.nouveauTypeLibelle });

                        // Réinitialisez le champ de saisie du libellé
                        this.nouveauTypeLibelle = "";

                        // // Fermez la boîte de dialogue (modal)
                        // $('#addTypeEvenement').modal('hide');
                    } else {
                        console.log(response.data.msg);
                    }
                })
                .catch((error) => {
                    this.loaderAdd = false;
                    this.showToatWarning("Echec de l'ajout du type d'evènement");
                    console.log(error);
                });
        },

        afficherTypeEvenement() {
            axiosInstance
                .get("api/typeevenements")
                .then((response) => {
                    if (response.data && response.data.success) {
                        console.log("Récupération réussie:", response.data);
                        this.typesEvenements = response.data.liste;
                    } else {
                        console.log(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        ouvrirModalEdition(typeEvenement) {
            this.typeEvenementEnEdition.id = typeEvenement.id;
            this.typeEvenementEnEdition.libelle = typeEvenement.libelle;
        },

        mettreAJourTypeEvenement() {
            this.loaderPut = true;
            const typeEvenementEnEdition = {
                nouveauTypeLibelle: this.typeEvenementEnEdition.libelle, // Utilisez le modèle nouveauTypeLibelle
                userId: this.userId,
            };

            axiosInstance
                .post(`api/typeevenements/${this.typeEvenementEnEdition.id}`, typeEvenementEnEdition)
                .then((response) => {
                    this.loaderPut = false;
                    this.showToatSuccess(response.data.msg);
                    this.fermerModal("editTypeEvenement");
                    this.afficherTypeEvenement();
                })
                .catch((error) => {
                    this.loaderPut = false;
                    console.log(error);
                    this.showToatWarning("Echec de l'edition du type d'evènement");
                });
        },

        ouvrirConfirmationSuppression(typeEvenement) {
            // this.confirmationSuppressionVisible = true; // Afficher le modal de confirmation
            this.typesEvenements.id = typeEvenement.id;
        },

        supprimerTypeEvenementConfirm() {
            this.loaderDelete = true;
            // recupération de l'id du  type evènement
            const typeEvenementId = this.typesEvenements.id;

            //axiosInstance
            axiosInstance
                .delete(`api/typeevenements/${typeEvenementId}`, this.typeEvenementEnEdition)
                .then((response) => {
                    this.loaderDelete = false;
                    this.showToatSuccess(response.data.msg);
                    this.fermerModal("supinvite");
                    this.afficherTypeEvenement();
                })
                .catch((error) => {
                    this.loaderDelete = false;
                    console.log(error);
                    this.showToatWarning("Echec de suppression du type d'evènement");
                });
        },
    },

    mounted() {

        // Récupérez le token depuis sessionStorage
        const accessToken = sessionStorage.getItem('accessToken');

        // Vérifiez si le token est présent et affectez-le à la propriété accessToken.
        if (accessToken) {
            this.accessToken = accessToken;
        }
        // Récupérez l'ID de l'utilisateur depuis une source appropriée
        const infoUser = JSON.parse(sessionStorage.getItem("infoUser"));
        this.userId = infoUser?.id;
        this.afficherTypeEvenement();
    },
};
</script>

<style scoped>
body {
    background-color: #f6f9ff;
    font-size: 17px;
}

a {
    color: #034101;
    text-decoration: none;
}

a:hover {
    color: #010113;
    text-decoration: none;
}

#main {
    margin-top: 5px;
    padding: 20px 30px;
}

@media (max-width: 1199px) {
    #main {
        padding: 20px;
    }
}

.pagetitle {
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
    padding-bottom: 1px;
}

.pagetitle h1 {
    font-size: 24px;
    font-weight: 600;
    color: #034101;
}

.pagetitle a {
    color: #025202a9;
}

.card {
    border: none;
}

.card-title {
    font-weight: bold;
}

.card-title h5 {
    font-size: 20px;
    color: #000918;
}

.card-body {
    padding: 0 20px 20px 20px;
}

.obligatoire {
    color: #034101;
}
</style>
