<template>
    <div class="container">
        <div class="row">
            <div class="liste shadow shadow-primary">
                <p class="fs-5 para0">Edition</p>
                <p>Nom Evènement/Afficher liste/edition</p>
            </div>
        </div>

        <div class="row mt-4">
            <div class="user shadow">
                <div>
                    <form class="mt-6">
                        <div class="row ml-3 mr-3 mt-2">
                            <div class="col-md-6 mb-4">
                                <label for="nom" class="form-label">Nom liste</label>
                                <input type="text" id="nom" class="form-control" required placeholder="Nom liste" />
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="categorie" class="form-label">Catégorie</label>
                                <select id="categorie" class="form-select" required>
                                    <option value="VIP">VIP</option>
                                    <option value="Standard">Standard</option>
                                </select>
                            </div>
                        </div>
                        <div class="row ml-3 mr-3 mt-2">
                            <div class="col-md-6 mb-4">
                                <label for="message" class="form-label">Message</label>
                                <textarea id="message" class="form-control" rows="3" placeholder="Insérer votre texte ici"></textarea>
                            </div>
                            <div class="col-md-6 d-flex justify-content-center align-items-center">
                                <button class="btn mt-2" type="submit" style="background-color: rgba(1, 87, 1, 0.945); color: white; width: 25%">Edition</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

import VueRouter from "vue-router";
export default {
    data() {
        return {
            info: true,
            users: false,
            password: false,
            activeTab: 1,
        };
    },
    methods: {
        toggleTab(tabNumber) {
            this.activeTab = tabNumber;
        },
        toogle1() {
            this.users = false;
            this.password = false;
            this.info = true;
        },
        toogle2() {
            this.password = false;
            this.info = false;
            this.users = true;
        },
        toogle3() {
            this.users = false;
            this.info = false;
            this.password = true;
        },
    },
};
</script>

<style scoped>
.liste {
    margin-top: 20px;
    width: 103%;
    background-color: white;
    margin-right: 50px;
}
.para0 {
    font-weight: bold;
    margin-bottom: 1px;
}

.user {
    background-color: white;
    margin-bottom: 30px;
    border: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    width: 103%;
}

.para1 {
    font-weight: bold;
    border-left: 5px solid rgb(21, 145, 42);
}
.para2 {
    font-weight: bold;
    color: rgba(1, 87, 1, 0.945);
}
input {
    background-color: #f6f9ff;
}
select {
    background-color: #f6f9ff;
}
textarea {
    background-color: #f6f9ff;
}
</style>
