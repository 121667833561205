<template>
    <div class="container">
        <div class="row">
            <div class="pagetitle titre">
                <nav style="--bs-breadcrumb-divider: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E')" aria-label="breadcrumb">
                    <ol class="breadcrumb m-3">
                        <li class="breadcrumb-item"><a>Autres liste</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Liste total invité</li>
                    </ol>
                </nav>

                <h1 class="m-3" style="font-size: 24px; margin-bottom: 0; font-weight: 600; color: #034101">Liste Total Invité</h1>
                <hr class="border-bottom" />
                <div class="row mt-2">
                    <div class="user mb-5">
                        <div class="col-md-12 m-2">
                            <p class="fs-5 float-start" style="font-weight: bold">Total invités</p>
                        </div>
                        <br />

                        <div class="row mt-4">
                            <div class="card-body table-responsive">
                                <table class="table table-hover table-striped table-borderless datatable">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Identité</th>

                                            <th scope="col">Télephone</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Civilité</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(item, index) in total" :key="item.id">
                                            <th scope="row">{{ index + 1 }}</th>
                                            <td>{{ item.identite }}</td>

                                            <td>{{ item.telephone }}</td>
                                            <td>{{ item.email }}</td>
                                            <td>{{ item.civilite }}</td>
                                            <td class="text-end">
                                                <a @click="setIndexToDelete(item.id)" class="m-1" data-bs-toggle="modal" data-bs-target="#supinviteLIsteTot" style="padding: 0px 5px">
                                                    <i class="bi bi-trash-fill text-danger"></i>
                                                </a>
                                                <a @click="modalEditInvite(item)" class="m-1" data-bs-toggle="modal" data-bs-target="#editinvite" style="color: #019d0b">
                                                    <i class="bi bi-pencil-square"></i>
                                                </a>

                                                <a class="" href="m-1" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots-vertical text-success"></i>
                                                </a>
                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item d-flex align-items-center">Ajouter liste d'invités</a></li>
                                                    <li class="dropdown-divider"></li>
                                                    <li><a class="dropdown-item d-flex align-items-center">Afficher listes d'inivités</a></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-if="loadingTable" class="text-center mt-5">
                                    <!-- Loader/spinner code goes here -->
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <p>chargement du tableau...</p>
                                </div>

                                <!-- Modal pour la modification -->
                                <div class="modal fade" id="editinvite" tabindex="-1">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Modifier un invité</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <!-- Formulaire d'ajout d'invité -->
                                                <form @submit.prevent="editInvite">
                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="Nom" class="form-label">Nom</label>
                                                            <input type="text" id="Nom" v-model="nom" class="form-control" placeholder="Nom" />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="Prénom" class="form-label">Prénom</label>
                                                            <input type="text" id="prenom" v-model="prenom" class="form-control" placeholder="Prénom" />
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="email" class="form-label">E-mail</label>
                                                            <input type="email" id="email" v-model="email" class="form-control" placeholder="" />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="civilité" class="form-label">Civilité</label>
                                                            <select id="civilité" v-model="civilite" class="form-select">
                                                                <option value="Monsieur">Monsieur</option>
                                                                <option value="Madame">Madame</option>
                                                                <option value="Mademoiselle">Mademoiselle</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="date" class="form-label">Date Naissance</label>
                                                            <input type="date" id="date" v-model="date" class="form-control" placeholder=" " />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="domaine" class="form-label">Domaine Activité</label>
                                                            <input type="text" id="domaine" v-model="domaine" class="form-control" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="profession" class="form-label">Profession</label>
                                                            <input type="text" id="profession" v-model="profession" class="form-control" placeholder="" />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="titre" class="form-label">Titre</label>
                                                            <input type="text" id="titre" v-model="titre" class="form-control" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="matrimoniale" class="form-label">Situation matrimoniale</label>
                                                            <select id="matrimoniale" v-model="matrimoniale" class="form-select">
                                                                <option value="Celibataire">Célibataire</option>
                                                                <option value="Marie">Marié</option>
                                                                <option value="Divorce">Divorcé</option>
                                                                <option value="Fiance">Fiancé</option>
                                                                <option value="Veuf">Veuf</option>
                                                                <option value="Concubinage">Concubinage</option>
                                                                <option value="Couple">Couple</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6 mb-1">
                                                            <label for="adresse" class="form-label">Adresse</label>
                                                            <input type="text" id="adresse" v-model="adresse" class="form-control" placeholder="" />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="telephone" class="form-label">Numéro de téléphone</label>
                                                            <input type="number" id="telephone" v-model="telephone" class="form-control" placeholder="" />
                                                        </div>
                                                        <div class="justify-content-center col-md-6 mt-4">
                                                            <button class="btn" style="background: rgba(1, 87, 1, 0.945); color: white" type="submit">Modifier</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Modal pour la suppression -->
                                <div class="modal fade" id="supinviteLIsteTot" tabindex="-1">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Suppression</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Ëtes-vous sûr de vouloir supprimer ces données ???</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                                <a type="button" class="btn btn-danger" @click="supprimerInvite()">Supprimer</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useRoute } from "vue-router";
    import axiosInstance from "../api/axios";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    import { Modal } from "bootstrap/dist/js/bootstrap.js";

    export default {
        data() {
            return {
                total: [],
                loadingTable: true,
                inviteId: "",

                nom: "",
                prenom: "",
                email: "",
                civilite: "",
                date: "",
                domaine: "",
                profession: "",
                titre: "",
                matrimoniale: "",
                adresse: "",
                telephone: "",
            };
        },
        methods: {
            afficher() {
                this.loadingTable = true;
                axiosInstance
                    .get(`api/invites`)
                    .then((response) => {
                        if (response.data.status === 200) {
                            this.total = response.data.liste;
                            this.loadingTable = false;
                        }
                    })
                    .catch((error) => {
                        this.loadingTable = false;
                        this.showToastWarning("Erreur lors de l'affichage des invités. Veuillez réessayer.");
                        console.error("Une erreur s'est produite lors de la récupération des données:", error);
                    });
            },
            setIndexToDelete(id) {
                this.inviteId = id;
            },

            supprimerInvite() {
                axiosInstance
                    .delete(`api/invites/${this.inviteId}`)
                    .then((response) => {
                        if (response.data.status === 200) {
                            this.showToastSuccess(response.data.msg);

                            // Récupérer la liste mise à jour des invitations après une suppression réussie
                            this.afficher();
                            this.fermerModalSuppression();
                        } else {
                            this.showToastWarning(response.data.msg);
                            this.fermerModalSuppression();
                        }
                    })
                    .catch((error) => {
                        console.log("Erreur lors de la suppression de l'invité. Veuillez réessayer.");
                    });
            },

            fermerModalEdit() {
                var myModalEl = document.getElementById("editinvite");
                var modal = Modal.getInstance(myModalEl);
                modal.hide();
            },
            fermerModalSuppression() {
                var myModalEl = document.getElementById("supinviteLIsteTot");
                var modal = Modal.getInstance(myModalEl);
                modal.hide();
            },
            formatDateString(dateString) {
                // Convertit la chaîne de date en un objet Date
                const date = new Date(dateString);

                // Obtient les composants de la date
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");

                // Retourne la date formatée
                return `${year}-${month}-${day}`;
            },
            modalEditInvite(invite) {
                this.inviteId = invite.id;
                console.log(invite);
                var parties = invite.identite.split(" ");

                this.nom = parties[0];
                this.prenom = parties.slice(1).join(" ");
                this.telephone = invite.telephone_invite;

                this.email = invite.email;
                this.civilite = invite.civilite;
                this.date = invite.date_naissance;
                this.domaine = invite.domaine;
                this.profession = invite.profession;
                this.titre = invite.titre;
                this.matrimoniale = invite.matrimoniale;
                this.adresse = invite.adresse;
                this.telephone = invite.telephone;
            },

            editInvite() {
                const data = {
                    nom: this.nom,
                    prenom: this.prenom,
                    email: this.email,
                    civilite: this.civilite,
                    date_naissance: this.date_naissance,
                    domaine: this.domaine,
                    profession: this.profession,
                    titre: this.titre,
                    matrimoniale: this.matrimoniale,
                    adresse: this.adresse,
                    telephone: this.telephone,
                };

                axiosInstance
                    // .post(`api/evenements/${this.idEvenement}/listeinvites/${this.listeInviteId}/invites/${this.inviteId}`, data)
                    .post(`api/invites/${this.inviteId}`, data)
                    .then((response) => {
                        this.fermerModalEdit();
                        // this.showToastSuccess(response.data.msg);
                        let statut = response.data.status;
                        console.log(statut);

                        if (statut == "422") {
                            const backendErrors = response.data.msg;
                            for (let el in backendErrors) {
                                this.showToastInfo(`${backendErrors[el][0]}`);
                            }
                            this.isLoading = false;
                        } else {
                            this.showToastSuccess(`${response.data.msg}`);
                            this.isLoading = false;

                            setTimeout(() => {
                                this.$router.push("/");
                            }, 3000);
                        }

                        this.afficherliste();
                    })
                    .catch((error) => {
                        this.showToastWarning("Erreur lors de la modification");
                        console.error("Erreur lors de la requête GET pour les détails de l'invité :", error);
                    });
            },

            // old
            setEditData(item) {
                // old
                // Pré-remplir les champs du formulaire avec les données de l'invité sélectionné
                this.inviteId = item.id;
                this.identite = item.identite;

                this.email = item.email;
                this.civilite = item.civilite;
                this.date = item.date_naissance;
                this.domaine = item.domaine;
                this.profession = item.profession;
                this.titre = item.titre;
                this.matrimoniale = item.matrimoniale;
                this.adresse = item.adresse;
                this.telephone = item.telephone;
            },
            // old
            editInvites() {
                // Vérifiez si this.inviteId est défini
                if (this.inviteId) {
                    const data = {
                        nom: this.nom,
                        prenom: this.prenom,
                        email: this.email,
                        civilite: this.civilite,
                        date_naissance: this.date_naissance,
                        domaine: this.domaine,
                        profession: this.profession,
                        titre: this.titre,
                        matrimoniale: this.matrimoniale,
                        adresse: this.adresse,
                        telephone: this.telephone,
                    };

                    axiosInstance
                        .post(`api/invites/${this.inviteId}`, data)
                        .then((response) => {
                            this.fermerModalEdit();
                            this.showToastSuccess(response.data.msg);
                            this.afficher();
                        })
                        .catch((error) => {
                            this.showToastWarning("Erreur lors de la modification");
                            console.error("Erreur lors de la requête POST pour la modification de l'invité :", error);
                        });
                } else {
                    console.error("L'ID de l'invité n'est pas défini. Assurez-vous que modalEditInvite est appelée correctement.");
                    this.showToastInfo("L'ID de l'invité n'est pas défini. Assurez-vous que modalEditInvite est appelée correctement.");
                }
            },

            showToastWarning(message) {
                toast.warning(message, {
                    autoClose: 3000,
                });
            },
            showToastSuccess(message) {
                toast.success(message, {
                    autoClose: 3000,
                });
            },
            showToastInfo(message) {
                toast.info(message, {
                    autoClose: 3000,
                });
            },
        },
        mounted() {
            this.afficher();
        },
    };
</script>

<style scoped>
    .spinner-border {
        width: 3rem;
        height: 3rem;
        border-width: 0.25em;
    }

    .pagetitle {
        margin-top: 15px;
        margin-bottom: 10px;
        background-color: white;
        padding: 10px;
        padding-bottom: 1px;
    }

    .pagetitle a {
        color: #025202a9;
        text-decoration: none;
    }

    .liste {
        margin-top: 20px;
        width: 103%;
        background-color: white;
        margin-right: 50px;
    }
    .para0 {
        font-weight: bold;
        margin-bottom: 1px;
    }

    .user {
        border: none;
    }

    /* Style de base du bouton du dropdown */
    .dropbtn {
        color: rgba(1, 87, 1, 0.945);

        cursor: pointer;
    }

    /* Style du dropdown au survol */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Style des options du dropdown */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 150px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        padding: 9px;
        text-decoration: none;
        display: block;
        color: #333;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }
    .btn1 {
        color: rgba(1, 87, 1, 0.945);
        cursor: pointer;
    }
    .btn2 {
        color: red;
        cursor: pointer;
    }

    .card {
        border: none;
        border-radius: 5px;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    }
    .card-title {
        padding: 20px;
        font-weight: bold;
    }
    .card-title h5 {
        font-size: 20px;
        color: #000918;
    }
    .card-body {
        padding: 0 20px 20px 20px;
    }
    .obligatoire {
        color: #034101;
    }

    .ajout {
        background-color: #013f04;
        color: white;
    }

    .ajout:hover {
        color: #013f04;
        text-decoration: none;
        background-color: #013f04;
    }

    #main {
        margin-top: 20px;
        padding: px;
        transition: all 0.3s;
    }

    @media (max-width: 1199px) {
        #main {
            padding: 10px;
        }
    }
</style>
