<template>
    <main id="main" class="main body">
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='%236c757d'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                <ol class="breadcrumb m-3">
                    <li class="breadcrumb-item"><a href="#">Evènement</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Mes Evènements</li>
                    <li class="breadcrumb-item active" aria-current="page">Ajouter évènement</li>
                </ol>
            </nav>
            <h1 class="m-3">Evènement</h1>
            <hr class="border-bottom" />

            <section class="section">
                <div class="card">
                    <div class="card-title">
                        <div class="">
                            <router-link to="/dashboard/liste_evenement" class="text-decoration-none btn border border-3 carde px-2 float-end fw-bold"> <i class="bi bi-journal-text"></i> Vos évènements </router-link>
                        </div>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm()" class="row g-3">
                            <div class="col-lg-6 col-md-12">
                                <label for="titre" class="form-label">Titre<span class="obligatoire">*</span></label>
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="titre" required name="titre" v-model="formData.titre" />
                                    <label for="titre">Titre</label>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <label for="fin" class="form-label">Date début <span class="obligatoire">*</span></label>
                                <div class="row g-3">
                                    <div class="col-6">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" id="debut" required name="debut" v-model="formData.debut" />
                                            <label for="debut">Date début</label>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="form-floating">
                                            <input type="time" class="form-control" step="1" id="heureDebut" required name="heureDebut" v-model="formData.heureDebut" />
                                            <label for="heureDebut">Heure</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <label for="type" class="form-label">Type <span class="obligatoire">*</span></label>
                                <div class="">
                                    <select class="form-select" id="type" aria-label="type" required name="type" v-model="formData.type">
                                        <option disabled value="">Veuillez choisir un type d'évenement</option>
                                        <option v-for="item in typeEvenement" :value="item.id">{{ item.libelle }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <label for="fin" class="form-label">Date fin</label>
                                <div class="row g-3">
                                    <div class="col-6">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" id="fin" name="fin" v-model="formData.fin" />
                                            <label for="fin">Date fin</label>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="form-floating">
                                            <input type="time" pattern="[0-2][0-9]:[0-5][0-9]:[0-5][0-9]" step="1" placeholder="HH:MM:SS" class="form-control" id="heureFin" name="heureFin" v-model="formData.heureFin" />
                                            <label for="heureFin">Heure</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <label for="description" class="form-label">Description <span class="obligatoire">*</span></label>
                                <div class="form-floating">
                                    <textarea class="form-control" id="description" style="height: 100px" required name="description" v-model="formData.description"></textarea>
                                    <label for="description">Description</label>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <label for="dressCode" class="form-label">Dress-code</label>
                                <div class="form-floating">
                                    <textarea class="form-control" id="dressCode" style="height: 100px" name="dressCode" v-model="formData.dressCode"></textarea>
                                    <label for="dressCode">Dress-code</label>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <label for="lieu" class="form-label">Lieu <span class="obligatoire">*</span></label>
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="lieu" required name="lieu" v-model="formData.lieu" />
                                    <label for="lieu">Lieu</label>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <label for="nombre" class="form-label">Nombre d'inivté</label>
                                <div class="form-floating">
                                    <input type="number" class="form-control" id="nombre" name="nombre" v-model="formData.nombre" />
                                    <label for="nombre">Nombre d'inivté</label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <label for="entree">Sélectionnez un type d'entrée :</label>
                                <div class="d-flex justify-content-around align-items-center mt-4">
                                    <div class="d-flex">
                                        <input class="form-check-input me-3" type="radio" value="Libre" id="flexCheckIndeterminateA" name="entree" v-model="formData.entree" />
                                        <label class="form-check-label" for="flexCheckIndeterminate"> Libre </label>
                                    </div>
                                    <div class="d-flex">
                                        <input class="form-check-input me-3" type="radio" value="Payante" id="flexCheckIndeterminateB" name="entree" v-model="formData.entree" />
                                        <label class="form-check-label" for="flexCheckIndeterminate"> Payante </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6" v-if="formData.entree == 'Payante'">
                                <label for="titre" class="form-label">Entrée</label>

                                <div class="form-floating">
                                    <input type="number" class="form-control" id="tarif" name="entree" v-model="formData.tarif" />
                                    <label for="entree">Tarif</label>
                                </div>
                                <br />
                            </div>

                            <div class="">
                                <br />
                                <button type="submit" class="btn float-end mx-2" style="background-color: #013f04; color: white">Valider</button>
                                <button type="reset" class="btn btn-secondary float-end">Annuler</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
    import axiosInstance from "../api/axios";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    export default {
        data() {
            return {
                formData: {
                    titre: "",
                    debut: new Date(),
                    heureDebut: "",
                    type: "",
                    fin: new Date(),
                    heureFin: "",
                    description: "",
                    dressCode: "",
                    lieu: "",
                    nombre: 0,
                    entree: "",
                    userId: "",
                    tarif: 0,
                },

                typeEvenement: [],
            };
        },
        methods: {
            showToastWarning(message) {
                toast.warning(message, {
                    autoClose: 3000,
                });
            },
            showToastSuccess(message) {
                toast.success(message, {
                    autoClose: 3000,
                });
            },
            showToastInfo(message) {
                toast.info(message, {
                    autoClose: 3000,
                });
            },
            submitForm() {
                // console.log(this.formData);
                axiosInstance
                    .post("api/evenements", this.formData)
                    .then((response) => {
                        let statut = response.data.status;
                        if (statut === 422) {
                            const backendErrors = response.data.msg;
                            for (let el in backendErrors) {
                                this.showToastInfo(`${backendErrors[el][0]}`);
                            }
                        } else {
                            console.log(response.data.msg[0]);
                            this.showToastSuccess(response.data.msg[0]);
                        }
                    })

                    .catch((error) => {
                        // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
                        console.error(error);
                        this.showToastWarning("Echec de l'ajout d'evènement");
                    });
            },

            getTypeEvenement() {
                axiosInstance
                    .get("api/typeevenements")
                    .then((response) => {
                        this.typeEvenement = response.data.liste;
                    })

                    .catch((error) => {
                        // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
                    });
            },
        },

        mounted() {
            // Récupérez l'ID de l'utilisateur depuis une source appropriée
            const infoUser = JSON.parse(sessionStorage?.getItem("infoUser"));
            this.formData.userId = infoUser.id;

            this.getTypeEvenement();
        },
    };
</script>

<style scoped>
    body {
        background-color: #f6f9ff;
        font-size: 17px;
    }

    a {
        color: #013f04;
        text-decoration: none;
    }

    .carde:hover {
        color: white;
        text-decoration: none;
        background-color: #013f04;
    }

    #main {
        margin-top: 20px;
        padding: px;
        transition: all 0.3s;
    }

    @media (max-width: 1199px) {
        #main {
            padding: 10px;
        }
    }
    .pagetitle {
        margin-bottom: 10px;
        background-color: white;
        padding: 10px;
        padding-bottom: 1px;
    }

    .pagetitle h1 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 600;
        color: #034101;
    }
    .pagetitle a {
        color: #025202a9;
    }
    .card {
        border: none;
    }
    .card-title {
        font-weight: bold;
    }
    .card-title h5 {
        font-size: 20px;
        color: #000918;
    }
    .card-body {
        padding: 0 20px 20px 20px;

        margin-bottom: 10px;
    }
    .obligatoire {
        color: #034101;
    }
</style>
