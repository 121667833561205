import axios from "axios";

var config = {
    baseURL: "https://back-event-planner.digitafriqplus.com",
    timeout: 600 * 1000, // Timeout
    withCredentials: true, // Permet d'inclure les cookies dans les requêtes

    headers: {
        common: {
            Accept: "application/json",
            "Content-Type": "application/json,multipart/form-data",
        },
    },
};

//  instance Axios avec une configuration de base
const axiosInstance = axios.create(config);

/** Adding the request interceptors */
axiosInstance.interceptors.request.use((config) => {
    var getToken = sessionStorage.getItem("authToken");
    if (getToken != undefined || getToken != null) {
        config.headers.Authorization = `Bearer ${getToken}`;
    }

    return config;
});

export default axiosInstance;
