<template>
    <div class="container">
        <div class="row">
            <div class="pagetitle titre">
                <nav style="--bs-breadcrumb-divider: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E')" aria-label="breadcrumb">
                    <ol class="breadcrumb m-3">
                        <li class="breadcrumb-item"><a>Evènemennt</a></li>
                        <li class="breadcrumb-item active" aria-current="page"><router-link to="/dashboard/liste_evenement">Liste évènement</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">liste de présence</li>
                    </ol>
                </nav>

                <div>
                    <h1 class="m-3" style="font-size: 24px; margin-bottom: 0; font-weight: 600; color: #034101">Liste de présence</h1>
                    <div class="mr-3">
                        <hr class="border-bottom" />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="user shadow">
                        <div class="card-body table-responsive mt-3">
                            <table class="table table-hover table-striped table-borderless datatable">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Nom Liste</th>
                                        <th scope="col">Catégorie</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(liste, index) in listeAjoute" :key="index">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ formatDateString(liste.created_at) }}</td>
                                        <td>{{ liste.nom }}</td>
                                        <td>{{ liste.categorie }}</td>
                                        <td class="">
                                            <a @click="setIndexToDelete(liste.id)" class="m-1" data-bs-toggle="modal" data-bs-target="#supEvenement" style="padding: 0px 5px">
                                                <i class="bi bi-trash-fill text-danger"></i>
                                            </a>

                                            <a @click="remplirFormulaire(liste)" class="m-1" data-bs-toggle="modal" data-bs-target="#edit" style="color: #019d0b">
                                                <i class="bi bi-pencil-square"></i>
                                            </a>
                                            <a class="" data-bs-toggle="dropdown">
                                                <i class="bi bi-three-dots-vertical text-success"></i>
                                            </a>

                                            <ul class="dropdown-menu">
                                                <li>
                                                    <router-link to="" class="dropdown-item d-flex align-items-center" @click="goToDetail(liste)">Détails</router-link>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li>
                                                    <router-link to="" class="dropdown-item d-flex align-items-center" @click="goToAddInvite(liste)"> Ajouter invités </router-link>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li>
                                                    <router-link to="" class="dropdown-item d-flex align-items-center" @click="goToInvite(liste)">Afficher inivités</router-link>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="loadingTable" class="text-center mt-5">
                                <!-- Loader/spinner code goes here -->
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <p>chargement...</p>
                            </div>

                            <div class="modal fade" id="supEvenement" tabindex="-1">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Suppression</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <p>Êtes-vous sûr de vouloir supprimer cette liste d'invités ???</p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                            <button type="button" class="btn btn-danger" @click="supprimerListe">Supprimer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" ref="editModal" id="edit" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="exampleModalLabel">Modifier la liste f^f</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="m-3" @submit.prevent="modifierListe">
                                                <div class="row ml-3 mr-3 mt-2">
                                                    <div class="col-md-6 mb-4">
                                                        <label for="nom" class="form-label">Nom liste</label>
                                                        <input v-model="formData.nom" type="text" id="nom" class="form-control" required placeholder="" />
                                                    </div>
                                                    <div class="col-md-6 mb-4">
                                                        <label for="categorie" class="form-label">Catégorie</label>
                                                        <select v-model="formData.categorie" id="categorie" class="form-select" required>
                                                            <option value="VIP">VIP</option>
                                                            <option value="Standard">Standard</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row ml-3 mr-3 mt-2">
                                                    <div class="col-md-6 mb-4">
                                                        <label for="message" class="form-label">Message</label>
                                                        <textarea v-model="formData.message" id="message" class="form-control" rows="3" placeholder="Insérer votre texte ici"></textarea>
                                                    </div>
                                                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                                                        <button class="btn" type="submit" style="background-color: rgba(1, 87, 1, 0.945); color: white">Modifier</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useRoute } from "vue-router";
    import axiosInstance from "../api/axios";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    import { Modal } from "bootstrap/dist/js/bootstrap.js";

    export default {
        data() {
            return {
                loadingTable: true,
                idEvenement: null,
                indexToDelete: null,
                formData: {
                    nom: "",
                    categorie: "VIP",
                    message: "",
                },
                listeAjoute: null,
                listeInviteId: "",
            };
        },
        methods: {
            formatDateString(dateString) {
                // Convertit la chaîne de date en un objet Date
                const date = new Date(dateString);

                // Obtient les composants de la date
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");

                // Retourne la date formatée
                return `${year}-${month}-${day}`;
            },
            goToAddInvite(liste) {
                console.log(`'/dashboard/liste_invite2/' +  ${this.idEvenement} + '/ajout_invite' +  ${liste.id} `);
                this.$router.push({
                    name: "ajout_invite",
                    params: {
                        idEvenement: this.idEvenement,
                        idListe_invite: liste.id,
                    },
                });
            },
            goToDetail(liste) {
                this.$router.push({
                    name: "detail_liste",
                    params: {
                        idEvenement: this.idEvenement,
                        idListe_invite: liste.id,
                    },
                });
            },
            goToInvite(liste) {
                this.$router.push({
                    name: "voir_invite",
                    params: {
                        idEvenement: this.idEvenement,
                        idListe_invite: liste.id,
                    },
                });
            },
            showToastWarning(message) {
                toast.warning(message, {
                    autoClose: 3000,
                });
            },
            showToastSuccess(message) {
                toast.success(message, {
                    autoClose: 3000,
                });
            },
            showToastInfo(message) {
                toast.info(message, {
                    autoClose: 3000,
                });
            },
            fermerModalModification() {
                var myModalEl = document.getElementById("edit");
                var modal = Modal.getInstance(myModalEl);
                modal.hide();
            },
            fermerModalSuppression() {
                var myModalEl = document.getElementById("supEvenement");
                var modal = Modal.getInstance(myModalEl);
                modal.hide();
            },

            afficherliste() {
                this.loadingTable = true;

                axiosInstance
                    .get(`api/evenements/${this.idEvenement}/invite_present`)
                    .then((response) => {
                        this.loadingTable = false;
                        if (response.data.status === 200) {
                            // Assurez-vous que la clé "liste" correspond à la structure réelle de votre réponse
                            this.listeAjoute = response.data.liste;
                        }
                    })
                    .catch((error) => {
                        this.loadingTable = false;
                        console.error("Erreur lors de la requête GET pour les listes :", error);
                        if (error.response) {
                            console.error("Réponse du serveur :", error.response.data);
                        }

                        this.loadingTable = false;
                    });
            },

            supprimerListe() {
                axiosInstance
                    .delete(`api/evenements/${this.idEvenement}/listeinvites/${this.listeInviteId}`)
                    .then((response) => {
                        this.showToastSuccess(response.data.msg);
                        this.fermerModalSuppression();
                    })
                    .catch((error) => {
                        this.showToastWarning("Erreur lors de la suppression de la liste. Veuillez réessayer.");
                    })
                    .finally(() => {
                        // Réinitialise l'indexToDelete après la suppression
                        this.indexToDelete = null;
                    });
            },
            remplirFormulaire(data) {
                this.formData = {
                    nom: data.nom,
                    categorie: data.categorie,
                    message: data.message,
                };
                this.listeInviteId = data.id;
            },

            setIndexToDelete(id) {
                this.listeInviteId = id;
            },

            modifierListe() {
                const route = useRoute();

                axiosInstance
                    .post(`api/evenements/${this.idEvenement}/listeinvites/${this.listeInviteId}`, this.formData)
                    .then((response) => {
                        console.log("Réponse de la requête POST :", response);
                        this.showToastSuccess(response.data.msg);
                        this.fermerModalModification();
                        this.afficherliste();
                    })
                    .catch((error) => {
                        console.error("Erreur lors de la modification de la liste :", error);
                        this.showToastWarning("Erreur lors de la modification de la liste ");
                        if (error.response) {
                            console.error("Réponse du serveur :", error.response.data);
                        }
                    });
            },
        },

        mounted() {
            const route = useRoute();
            this.idEvenement = route.params.idEvenement;
            this.afficherliste();
        },
    };
</script>

<style scoped>
    .spinner-border {
        width: 3rem;
        height: 3rem;
        border-width: 0.25em;
    }
    .disabled-link {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .pagetitle {
        margin-top: 15px;
        background-color: white;
        padding: 10px;
        padding-bottom: 1px;
    }

    .pagetitle a {
        color: #025202a9;
        text-decoration: none;
    }

    .user {
        border: none;
    }

    .para1 {
        font-weight: bold;
        border-left: 5px solid rgb(21, 145, 42);
    }

    .para2 {
        font-weight: bold;
        color: rgba(1, 87, 1, 0.945);
    }

    input,
    select,
    textarea {
        background-color: #f6f9ff;
    }
</style>
