<template>
    <div class="container">
        <div class="row">
            <div class="profil shadow shadow-primary border-l-5">
                <p class="mt-2">Profil</p>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-xl-3 h-25 user shadow pt-4 d-flex flex-column align-items-center">
                <div class="position-relative mb-3 d-flex justify-content-center align-items-center" style="width: 80px; height: 80px">
                    <svg v-if="!showProfil" width="100px" height="100px" class="_image svgstart-50 _translate-middle-x" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9ZM12 20.5C13.784 20.5 15.4397 19.9504 16.8069 19.0112C17.4108 18.5964 17.6688 17.8062 17.3178 17.1632C16.59 15.8303 15.0902 15 11.9999 15C8.90969 15 7.40997 15.8302 6.68214 17.1632C6.33105 17.8062 6.5891 18.5963 7.19296 19.0111C8.56018 19.9503 10.2159 20.5 12 20.5Z" fill="#013f04"></path>
                        </g>
                    </svg>
                    <img v-else style="width: 100px; height: 100px" class="rounded-circle" alt="image" :src="imgUrl" />
                    <i title="cliquer pour choisir une image" class="bi bi-pencil-square position-absolute text-white px-1 rounded bottom-0 end-0 shadow-sm" style="background-color: rgba(1, 87, 1, 0.945); color: white">
                        <input type="file" class="position-relative -ms-2 opacity-0 index" style="width: 16px; height: 16px; margin-left: -16px" ref="logoInput" v-on:change="handleFileUpload()" />
                    </i>
                    <!-- <input type="file" class="position-absolute bg-primary text-black px-1 rounded bottom-0 end-0 z-index-1" style="width: 16px; height: 16px" ref="logoInput" v-on:change="handleFileUpload()" /> -->
                </div>

                <p class="text-center para1">{{ infoUser?.identite }}</p>
                <button type="button" class="btn my-3" style="background-color: rgba(1, 87, 1, 0.945); color: white" @click="changerPhoto" :disabled="isActive">Mettre à jour</button>
            </div>
            <div class="col-xl-8 user information shadow">
                <div class="row mx-4 span0">
                    <div @click="toggleTab(1)" class="col-sm">
                        <span :class="{ 'border-bottom border-3 border-success': activeTab === 1 }"> Informations </span>
                    </div>
                    <div @click="toggleTab(2)" class="col-sm">
                        <span :class="{ 'border-bottom border-3 border-success ': activeTab === 2 }"> Modifier Profil </span>
                    </div>
                    <div @click="toggleTab(3)" class="col-sm">
                        <span :class="{ 'border-bottom border-3 border-success': activeTab === 3 }"> Changer Mot de passe </span>
                    </div>
                </div>

                <!--informations-->

                <div v-if="activeTab === 1" class="mt-4">
                    <span>Nom : {{ infoUser?.identite }}</span> <br />
                    <br />
                    <span>Adresse : {{ infoUser?.adresse }}</span> <br />
                    <br />
                    <span>Sexe : {{ infoUser?.sexe }}</span
                    ><br />
                    <br />
                    <span>Téléphone : {{ infoUser?.telephone }}</span
                    ><br />
                    <br />
                    <span>Etat Civil : {{ infoUser?.civilite }}</span
                    ><br />
                    <br />
                    <span>Date de Naissance : {{ infoUser?.naissance }}</span
                    ><br />
                    <br />
                    <span>Pays : {{ infoUser?.pays }}</span
                    ><br />
                    <br />
                    <span>Email : {{ infoUser?.email }}</span> <br />
                </div>

                <!--Modifier profil-->

                <div v-if="activeTab === 2" class="">
                    <form @submit.prevent="updateUser" class="">
                        <div class="row m-4">
                            <div class="col-sm-12">
                                <label for="fullName" class="form-label">Identité</label>
                                <input type="text" id="fullName" v-model="identite" class="form-control" placeholder="" />
                            </div>
                            
                        </div>
                        <div class="row m-4">
                            <div class="col-md-6">
                                <label for="email" class="form-label">E-mail</label>
                                <input type="email" id="email" v-model="email" class="form-control" />
                            </div>
                            <div class="col-md-6">
                                <label for="phoneNumber" class="form-label">Numéro de téléphone</label>
                                <input type="number" id="phoneNumber" v-model="phoneNumber" class="form-control" />
                            </div>
                        </div>
                        <div class="row m-4">
                            <div class="col-md-6">
                                <label for="adresse" class="form-label">Adresse</label>
                                <input type="text" id="adresse" v-model="adresse" class="form-control" />
                            </div>
                            <div class="col-md-6">
                                <label for="dateOfBirth" class="form-label">Date de Naissance</label>
                                <input type="date" id="dateOfBirth" v-model="dateOfBirth" class="form-control" />
                            </div>
                        </div>
                        <div class="row m-4">
                            <div class="col-md-6">
                                <label for="civilite" class="form-label">Civilité</label>
                                <select id="civilite" v-model="civilite" class="form-select">
                                    <option value="Monsieur">Monsieur</option>
                                    <option value="Madame">Madame</option>
                                    <option value="Mademoiselle">Mademoiselle</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="type" class="form-label">Type</label>
                                <select id="type" v-model="type" class="form-select">
                                    <option value="Administrateur">Administrateur</option>
                                    <option value="Hote">Hote</option>
                                    <option value="Organisateur">Organisateur</option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="submit" class="btn mt-2" style="background-color: rgba(1, 87, 1, 0.945); color: white">Mettre à jour</button>
                        </div>
                    </form>
                </div>

                <!--  Changer Mot de passe-->

                <div v-if="activeTab === 3">
                    <div class="mb-3 row justify-content-center align-items-center d-flex mt-5">
                        <form @submit.prevent="changePassword()">
                            <label for="password" class="form-label">Ancien mot de passe</label>
                            <input type="password" id="password" v-model="formPassword.current_password" class="form-control" />
                            <div class="mb-3">
                                <label for="newPassword" class="form-label">Nouveau mot de passe</label>
                                <input type="password" id="newPassword" v-model="formPassword.new_password" class="form-control" />
                            </div>
                            <div class="mb-3">
                                <label for="confirmNewPassword" class="form-label">Confirmer nouveau mot de passe</label>
                                <input type="password" id="confirmNewPassword" v-model="formPassword.new_password_confirmation" class="form-control" />
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="submit" class="btn mt-2" style="background-color: rgba(1, 87, 1, 0.945); color: white">Valider</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { ref, onMounted, reactive } from "vue";
export default {
    data() {
        return {
            info: true,
            users: false,
            password: false,
            activeTab: 1,
            profil: null,
            infoUser: {},
            identite: "",
        
            email: "",
            phoneNumber: "",
            adresse: "",
            civilite: "",
            type: "",
            dateOfBirth: "",
            formPassword: {
                current_password: "",
                new_password: "",
                new_password_confirmation: "",
            },
            photo: {},
        };
    },
    setup() {
        const logoInput = ref(null); // Créez une référence au champ input
        const selectedFile = ref(null);
        const infoUser = ref({});
        const imgUrl = ref("");
        const showProfil = ref(false);
        const isActive = ref(true);
        const message = ref("Veuillez choisir une image d'abord");

        const getStoredProfilePhoto = () => {
            const storedPhotoUrl = sessionStorage.getItem("profilePhotoUrl");
            if (storedPhotoUrl) {
                imgUrl.value = storedPhotoUrl;
                showProfil.value = true;
            }
        };
        onMounted(() => {
            infoUser.value = JSON.parse(sessionStorage.getItem("infoUser"));
            getStoredProfilePhoto();
            getMiniature();
        });

        const handleFileUpload = () => {
            // Accédez au fichier sélectionné

            const maxWidth = 800;
            const maxHeight = 800;

            if (logoInput.value.files.length > 0) {
                const file = logoInput.value.files[0];

                const image = new Image();

                image.onload = function () {
                    if (image.width <= maxWidth && image.height <= maxHeight) {
                        isActive.value = false;
                        showProfil.value = true;
                        message.value = "";
                    } else {
                        alert("Les dimensions de l'image doivent être de 800x800 pixels maximum.");
                    }
                    return selectedFile.value;
                };

                selectedFile.value = logoInput.value.files[0];

                // Charger l'image pour obtenir ses dimensions
                const objectURL = URL.createObjectURL(file);
                image.src = objectURL;
                imgUrl.value = URL.createObjectURL(file);
            }
        };

        const changerPhoto = function () {
            console.log(selectedFile.value);
            let file = {
                profile_photo: selectedFile.value,
            };
            // /${infoUser.value.id}
            axiosInstance
                .post(`api/add_profile_photo`, file)
                .then((response) => {
                    showToatSuccess(response.data.msg);
                    // this.showToatSuccess(response.data.msg);
                    console.log("edition réussi:", response.data);
                    getMiniature();
                })
                .catch((error) => {
                    showToatWarning("Echec d'edition de profil");
                    // this.loaderAdd = false;
                    // this.showToatWarning("Echec d'edition de profil");
                    // console.log(error);
                });
        };

        const getMiniature = function () {
            axiosInstance
                .get(`api/miniature_photo`)
                .then((response) => {
                    console.log(response.data.thumbnail_path);
                    if (response.data?.thumbnail_path) {
                        showProfil.value = true;
                        localStorage.setItem("profilImage", response.data.thumbnail_path);
                        imgUrl.value = response.data?.thumbnail_path;
                    } else {
                        showProfil.value = false;
                        imgUrl.value = "";
                    }
                    // this.showToatSuccess(response.data.msg);
                })
                .catch((error) => {
                    // this.loaderAdd = false;
                    // this.showToatWarning("Echec d'edition de profil");
                });
        };

        const showToatWarning = function (message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        };
        const showToatSuccess = function (message) {
            toast.success(message, {
                autoClose: 3000,
            });
        };
        const showToatInfo = function (message) {
            toast.info(message, {
                autoClose: 3000,
            });
        };

        return {
            logoInput,
            selectedFile,
            isActive,
            message,
            handleFileUpload,
            changerPhoto,
            infoUser,
            onMounted,
            getMiniature,
            showToatWarning,
            showToatSuccess,
            showToatInfo,
            imgUrl,
            showProfil,
            getStoredProfilePhoto,
        };
    },
    methods: {
        showToatWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToatSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToatInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },

        toggleTab(tabNumber) {
            this.activeTab = tabNumber;
        },
        toogle1() {
            this.users = false;
            this.password = false;
            this.info = true;
        },
        toogle2() {
            this.password = false;
            this.info = false;
            this.users = true;
        },
        toogle3() {
            this.users = false;
            this.info = false;
            this.password = true;
        },

        updateUser() {
            const userData = {
                identite: this.identite,
              
                email: this.email,
                phoneNumber: this.phoneNumber,
                adresse: this.adresse,
                civilite: this.civilite,
                type: this.type,
                dateOfBirth: this.dateOfBirth,
            };

            axiosInstance
                .post(`api/profile`, userData)
                .then((response) => {
                    console.log("Edition réussie:", response.data);
                    this.showToatSuccess(response.data.msg);
                    if (response.data && response.data.success) {
                        // Mettez à jour les données d'infoUser avec les nouvelles données
                        this.infoUser.identite = this.identite;
                      
                        this.infoUser.adresse = this.adresse;
                        this.infoUser.telephone = this.phoneNumber;
                        this.infoUser.civilite = this.civilite;
                        this.infoUser.naissance = this.dateOfBirth;
                        this.infoUser.email = this.email;
                        sessionStorage.setItem("infoUser", JSON.stringify(response.data.infos));

                        // Videz les champs après la soumission du formulaire
                        this.identite = "";
                        
                        this.adresse = "";
                        this.phoneNumber = "";
                        this.civilite = "";
                        this.dateOfBirth = "";
                        this.email = "";
                    } else {
                        console.log(response.data.msg);
                    }
                })
                .catch((error) => {
                    this.loaderAdd = false;
                    this.showToatWarning("Echec d'edition de profil");
                    console.log(error);
                });
        },
        changePassword() {
            axiosInstance
                .post(`api/change_password/${this.infoUser.id}`, this.formPassword)
                .then((response) => {
                    this.showToatSuccess(response.data.msg);
                    if (response.data && response.data.success) {
                        console.log("edition réussi:", response.data);
                    } else {
                        console.log(response.data.msg);
                    }
                })
                .catch((error) => {
                    this.loaderAdd = false;
                    this.showToatWarning("Echec d'edition de profil");
                    console.log(error);
                });
        },
    },
    mounted() {
        // Appelez la méthode pour récupérer les données de l'utilisateur
        this.infoUser = JSON.parse(sessionStorage.getItem("infoUser"));

        this.identite = this.infoUser.identite;
        
        this.email = this.infoUser.email;
        this.phoneNumber = this.infoUser.telephone;
        this.adresse = this.infoUser.adresse;
        this.civilite = this.infoUser.civilite;
        this.type = this.infoUser.type;
    },
};
</script>

<style scoped>
.shadows {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.322);
}
.index {
    z-index: 10;
}
.profil {
    margin-top: 20px;
    width: 103%;
    background-color: white;
    margin-right: 50px;
    border-left: 3px solid #013f04;
}
.information {
    background-color: white;
    margin-left: 15px;
    width: 73%;
}
.user {
    background-color: white;
    margin-bottom: 30px;
    border: none;
    padding: 20px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    padding: 20px;
}
.image {
    margin-left: 100px;
}
.para1 {
    font-weight: bold;
}
span {
    font-weight: bold;
    margin-left: 10px;
}
.span0 {
    cursor: pointer;
}
.passwords {
    width: 50%;
}
input {
    background-color: #f6f9ff;
}
</style>
