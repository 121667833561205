import { VueRouter, createRouter, beforeEach, createWebHashHistory } from "vue-router";

import connexionView from "../views/connexionView.vue";
import inscriptionView from "../views/inscriptionView.vue";
import test from "../views/test.vue";
import mot_passeView from "../views/mot_passeView.vue";
import reinitialisationView from "../views/reinitialisationView.vue";
import Layout from "../layouts/dashboard.vue";
import profil from "../views/profilView.vue";
import ajout_liste from "../views/ajout_listeView.vue";
import afficher_liste from "../views/afficher_listeView.vue";
import ajout_invite from "../views/ajout_inviteView.vue";
import afficher_invite from "../views/afficher_inviteView.vue";
import afficher_invite2 from "../views/afficher_inviteView2.vue";
import afficher_invite_confirmer from "../views/afficher_invite_confirm_toutView.vue";
import afficher_invite_decliner from "../views/afficher_invite_declin_toutView.vue";
import edite_invite from "../views/edite_inviteView.vue";
import edite_liste from "../views/edite_listeView.vue";
import ajout_evenement from "../views/ajout_evenementView.vue";
import liste_evenement from "../views/liste_evenementView.vue";
import type_evenement from "../views/type_evenementView.vue";
// import liste_invite_confirm_tout from "../views/liste_invite_confirm_toutView.vue";
// import liste_invite_declin_tout from "../views/liste_invite_declin_toutView.vue";
// import liste_invite_infirm_tout from "../views/liste_invite_infirm_toutView.vue";
import detail_evenement from "../views/detail_evenementView.vue";
import detail_liste from "../views/detail_listeView.vue";
import total_liste from "../views/total_listeView.vue";
import liste_invite2 from "../views/liste_invite2View.vue";
import liste_presence from "../views/liste_presenceView.vue";
import liste_total_invite from "../views/liste_total_inviteView.vue";

function isAuthenticated() {
    var isAuthenticated = false;

    if (sessionStorage.getItem("authToken") !== null) {
        isAuthenticated = true;
    } else isAuthenticated = false;
    console.log(isAuthenticated);
    return isAuthenticated;
}

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/inscription",
            name: "inscription",
            component: inscriptionView,
            meta: { title: "inscription", requiredAuth: false },
        },
        {
            path: "/test",
            name: "test",
            component: test,
            meta: { title: "test", requiredAuth: false },
        },
        {
            path: "/",
            name: "connexion",
            component: connexionView,
            meta: { title: "connexion", requiredAuth: false },
        },
        {
            path: "/mot_passe",
            name: "mot_passe",
            component: mot_passeView,
            meta: { title: "connexion", requiredAuth: false },
        },
        {
            path: "/password/reset/:email/:tokenReset",
            name: "reinitialisation",
            component: reinitialisationView,
            meta: { title: "connexion", requiredAuth: false },
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Layout,
            meta: { title: "dashboard", requiredAuth: true },
            children: [
                {
                    path: "profil",
                    name: "profil",
                    component: profil,
                    meta: { title: "profil", requiredAuth: true },
                },
                {
                    path: "ajout_liste",
                    name: "ajout_liste",
                    component: ajout_liste,
                    props: true,
                    meta: { title: "ajout_liste", requiredAuth: true },
                },
                {
                    path: "afficher_liste",
                    name: "afficher_liste",
                    component: afficher_liste,
                    meta: { title: "afficher_liste", requiredAuth: true },
                },

                {
                    path: "liste_invite2/:idEvenement/ajouter_invite/:idListe_invite",
                    name: "ajout_invite",
                    component: ajout_invite,
                    meta: { title: "ajout_invite", requiredAuth: true },
                },
                {
                    path: "liste_invite2/:idEvenement/voir_invite/:idListe_invite",
                    name: "voir_invite",
                    component: afficher_invite2,
                    meta: { title: "ajout_invite", requiredAuth: true },
                },
                {
                    path: "liste_invite2/:idEvenement/invite_confirmer/:idListe_invite",
                    name: "invite_confirmer",
                    component: afficher_invite_confirmer,
                    meta: { title: "invite_confirmer", requiredAuth: true },
                },
                {
                    path: "liste_invite2/:idEvenement/invite_decliner/:idListe_invite",
                    name: "invite_decliner",
                    component: afficher_invite_decliner,
                    meta: { title: "invite_decliner", requiredAuth: true },
                },
                {
                    path: "liste_invite2/:idEvenement",
                    name: "liste_invite2",
                    component: liste_invite2,
                    meta: { title: "liste_invite2", requiredAuth: true },
                },
                {
                    path: "liste_presence/:idEvenement",
                    name: "liste_presence",
                    component: liste_presence,
                    meta: { title: "liste_presence", requiredAuth: true },
                },
                {
                    path: "afficher_invite",
                    name: "afficher_invite",
                    component: afficher_invite,
                    meta: { title: "afficher_invite", requiredAuth: true },
                },
                {
                    path: "edite_invite",
                    name: "edite_invite",
                    component: edite_invite,
                    meta: { title: "edite_invite", requiredAuth: true },
                },
                {
                    path: "edite_liste",
                    name: "edite_liste",
                    component: edite_liste,
                    meta: { title: "edite_liste", requiredAuth: true },
                },
                {
                    path: "ajout_evenement",
                    name: "ajout_evenement",
                    component: ajout_evenement,
                    meta: { title: "ajout_evenement", requiredAuth: true },
                },
                {
                    path: "liste_evenement",
                    name: "liste_evenement",
                    component: liste_evenement,
                    meta: { title: "liste_evenement", requiredAuth: true },
                },
                {
                    path: "type_evenement",
                    name: "type_evenement",
                    component: type_evenement,
                    meta: { title: "type_evenement", requiredAuth: true },
                },
                {
                    path: "detail_evenement/:idEvenement",
                    name: "detail_evenement",
                    component: detail_evenement,
                    meta: { title: "detail_evenement", requiredAuth: true },
                },
                {
                    path: "liste_invite2/:idEvenement/detail_liste/:idListe_invite",
                    name: "detail_liste",
                    component: detail_liste,
                    meta: { title: "detail_liste", requiredAuth: true },
                },
                {
                    path: "liste_total_invite",
                    name: "liste_total_invite",
                    component: liste_total_invite,
                    meta: { title: "liste_total_invite", requiredAuth: true },
                },
            ],
        },
        {
            path: "/about",
            name: "about",
            component: () => import("../views/AboutView.vue"),
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (isAuthenticated() && !to.meta.requiredAuth) {
        return next({ name: "dashboard" });
    } else if (!isAuthenticated() && to.meta.requiredAuth) {
        return next({ path: "/" });
    }
    return next();
});
export default router;
