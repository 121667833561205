import { createStore } from "vuex";

const store = createStore({
    state() {
        return {
            // Ici, vous pouvez initialiser votre objet dans le state.
            // Par exemple, un objet user vide.
            formData: {},
        };
    },
    mutations: {
        // Les mutations seront utilisées pour modifier l'objet dans le state.
        // Par exemple, pour mettre à jour l'utilisateur.
        updateFormData(state, newFormData) {
            state.user = newUser;
        },
    },
    actions: {
        // Les actions sont utilisées pour effectuer des opérations asynchrones
        // et appeler des mutations pour mettre à jour l'état.
        updateFormDataAction({ commit }, newFormData) {
            commit("updateFormData", newFormData);
        },
    },
    getters: {
        // Les getters permettent d'accéder aux données stockées dans l'état.
        getFormData(state) {
            return state.formData;
        },
    },
});

export default store;
