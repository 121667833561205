<template>
    <main id="main" class="main body">
        <div class="pagetitle titre">
            <nav style="--bs-breadcrumb-divider: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E')" aria-label="breadcrumb">
                <ol class="breadcrumb m-3">
                    <li class="breadcrumb-item"><a>Evènemennt</a></li>

                    <li class="breadcrumb-item active" aria-current="page">liste Evènement</li>
                </ol>
            </nav>
            <h1 class="m-3">Evènement</h1>
            <hr class="border-bottom" />

            <section class="section">
                <div class="card">
                    <div class="row g-3 card-title">
                        <div class="">
                            <router-link to="/dashboard/ajout_evenement" class="text-white text-decoration-none btn ajout float-end fw-bold fs-6"> <i class="bi bi-plus-circle"></i> Ajouter évènement</router-link>
                        </div>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-hover table-striped table-borderless datatable">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">N°</th>
                                    <th scope="col">Titre</th>
                                    <th scope="col">Date début</th>
                                    <th scope="col">Lieu</th>
                                    <th scope="col">Total invités</th>
                                    <th scope="col">Entrée</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, index) in evenement" :key="index">
                                <tr>
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.titre }}</td>
                                    <td>{{ item.date_debut }}</td>
                                    <td>{{ item.lieu }}</td>

                                    <td>{{ item.nombre_invite }}</td>
                                    <td>
                                        {{ item.entree }} <br />
                                        <span v-if="item.entree == 'payante'"> Prix: {{ item.tarif }} XOF</span>
                                    </td>
                                    <td class="text-end">
                                        <a class="m-1" data-bs-toggle="modal" data-bs-target="#supEvenement" style="padding: 0px 5px" @click="ouvrirModalSupprimerEvenement(item.id)">
                                            <i class="bi bi-trash-fill text-danger"></i>
                                        </a>
                                        <a class="m-1" data-bs-toggle="modal" data-bs-target="#editEvenement" aria-controls="" style="color: #019d0b" @click="modifier(item)">
                                            <i class="bi bi-pencil-square"></i>
                                        </a>
                                        <a class="" data-bs-toggle="dropdown">
                                            <i class="bi bi-three-dots-vertical text-success"></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li><button @click="goToDetail(item.id)" class="dropdown-item d-flex align-items-center text-black text-decoration-none d-flex">Détails</button></li>
                                            <li class="dropdown-divider"></li>

                                            <li><router-link :to="'/dashboard/liste_invite2/' + item.id" class="text-black text-decoration-none d-flex dropdown-item d-flex align-items-center"> Liste d'invité </router-link></li>
                                            <li class="dropdown-divider"></li>
                                            <li><router-link :to="'/dashboard/liste_presence/' + item.id" class="text-black text-decoration-none d-flex dropdown-item d-flex align-items-center"> Liste de présence </router-link></li>
                                            <li class="dropdown-divider"></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div v-if="loadingTable" class="text-center mt-5">
                            <!-- Loader/spinner code goes here -->
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <p>chargement du tableau...</p>
                        </div>

                        <!-- Modal pour la modification -->
                        <div class="modal fade" id="editEvenement" tabindex="-1">
                            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Modifier l'événement</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <!-- Ajoutez ici le contenu du formulaire pour la modification -->
                                        <form @submit.prevent="updateEvenement" class="row g-3">
                                            <div class="col-lg-6 col-md-12">
                                                <label for="titre" class="form-label">Titre <span class="obligatoire">*</span></label>
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" id="titre" required name="titre" v-model="formDataUpdate.titre" />
                                                    <label for="titre">Titre</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12">
                                                <label for="fin" class="form-label">Date début <span class="obligatoire">*</span></label>
                                                <div class="row g-3">
                                                    <div class="col-6">
                                                        <div class="form-floating">
                                                            <input type="date" class="form-control" id="debut" required name="debut" v-model="formDataUpdate.debut" />
                                                            <label for="debut">Date début</label>
                                                        </div>
                                                    </div>

                                                    <div class="col-6">
                                                        <div class="form-floating">
                                                            <input type="time" class="form-control" step="1" id="heureDebut" required name="heureDebut" v-model="formDataUpdate.heureDebut" />
                                                            <label for="heureDebut">Heure</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12">
                                                <label for="type" class="form-label">Type <span class="obligatoire">*</span></label>
                                                <div class="">
                                                    <select class="form-select" id="type" aria-label="type" required name="type" v-model="formDataUpdate.type">
                                                        <option disabled value="">Veuillez choisir un type d'événement</option>
                                                        <option v-for="type in typesEvenements" :value="type.id" :key="type.id">{{ type.libelle }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12">
                                                <label for="fin" class="form-label">Date fin</label>
                                                <div class="row g-3">
                                                    <div class="col-6">
                                                        <div class="form-floating">
                                                            <input type="date" class="form-control" id="fin" name="fin" v-model="formDataUpdate.fin" />
                                                            <label for="fin">Date fin</label>
                                                        </div>
                                                    </div>

                                                    <div class="col-6">
                                                        <div class="form-floating">
                                                            <input type="time" pattern="[0-2][0-9]:[0-5][0-9]:[0-5][0-9]" step="1" placeholder="HH:MM:SS" class="form-control" id="heureFin" name="heureFin" v-model="formDataUpdate.heureFin" />
                                                            <label for="heureFin">Heure</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12">
                                                <label for="description" class="form-label">Description <span class="obligatoire">*</span></label>
                                                <div class="form-floating">
                                                    <textarea class="form-control" id="description" style="height: 100px" required name="description" v-model="formDataUpdate.description"></textarea>
                                                    <label for="description">Description</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12">
                                                <label for="dressCode" class="form-label">Dress-code</label>
                                                <div class="form-floating">
                                                    <textarea class="form-control" id="dressCode" style="height: 100px" name="dressCode" v-model="formDataUpdate.dressCode"></textarea>
                                                    <label for="dressCode">Dress-code</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12">
                                                <label for="lieu" class="form-label">Lieu <span class="obligatoire">*</span></label>
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" id="lieu" required name="lieu" v-model="formDataUpdate.lieu" />
                                                    <label for="lieu">Lieu</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-6">
                                                <label for="nombre" class="form-label">Nombre d'inivté</label>
                                                <div class="form-floating">
                                                    <input type="number" class="form-control" id="nombre" name="nombre" v-model="formDataUpdate.nombre" />
                                                    <label for="nombre">Nombre d'inivté</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6">
                                                <label for="entree">Sélectionnez un type d'entrée :</label>
                                                <div class="d-flex justify-content-around align-items-center mt-4">
                                                    <div class="d-flex">
                                                        <input class="form-check-input me-3" :checked="formDataUpdate.entree == 'libre'" type="radio" value="Libre" id="flexCheckIndeterminateA" name="entree" v-model="formDataUpdate.entree" />
                                                        <label class="form-check-label" for="flexCheckIndeterminate"> Libre </label>
                                                    </div>
                                                    <div class="d-flex">
                                                        <input class="form-check-input me-3" :checked="formDataUpdate.entree == 'payante'" type="radio" value="Payante" id="flexCheckIndeterminateB" name="entree" v-model="formDataUpdate.entree" />
                                                        <label class="form-check-label" for="flexCheckIndeterminate"> Payante </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6" v-if="formDataUpdate.entree == 'Payante'">
                                                <label for="titre" class="form-label">Entrée</label>

                                                <div class="form-floating">
                                                    <input type="number" class="form-control" id="tarif" name="entree" v-model="formDataUpdate.tarif" />
                                                    <label for="entree">Tarif</label>
                                                </div>
                                                <br />
                                            </div>

                                            <div class="">
                                                <br />
                                                <button v-if="loader" class="btn float-end mx-2" style="background-color: #013f04; color: white" type="button">
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    chargement...
                                                </button>
                                                <button v-else type="submit" class="btn float-end mx-2" style="background-color: #013f04; color: white">Modifier</button>

                                                <button type="reset" class="btn btn-secondary float-end">Annuler</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal pour la suppression -->
                        <div class="modal fade" id="supEvenement" tabindex="-1">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Suppression</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Ëtes-vous sûr de vouloir supprimer ces données ???</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button v-if="loader" class="btn float-end mx-2" style="background-color: #013f04; color: white" type="button">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            chargement...
                                        </button>
                                        <button v-else @click="supprimerEvenement" type="button" class="btn btn-danger">Supprimer</button>

                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Retour</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
    import axiosInstance from "../api/axios";
    import { computed } from "vue";
    import { mapGetters, mapActions } from "vuex";
    import { Modal } from "bootstrap/dist/js/bootstrap.js";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";

    export default {
        data() {
            return {
                loadingTable: true,
                liste_evenement: [],
                evenementToEdit: {},
                typesEvenements: [],
                evenement: [],

                formData: {
                    titre: "",
                    debut: new Date(),
                    heureDebut: "",
                    type: "",
                    fin: new Date(),
                    heureFin: "",
                    description: "",
                    dressCode: "",
                    lieu: "",
                    nombre: 0,
                    entree: "",
                    userId: "",
                    tarif: 0,
                },
                evenementId: "",
                formDataUpdate: {
                    titre: "",
                    debut: new Date(),
                    type: "",
                    fin: new Date(),
                    description: "",
                    dressCode: "",
                    heureDebut: "",
                    heureFin: "",
                    lieu: "",
                    nombre: 0,
                    entree: "",
                    userId: "",
                    tarif: 0,
                },
            };
        },
        methods: {
            goToDetail(id) {
                this.$router.push({
                    name: "detail_evenement",
                    params: {
                        idEvenement: id,
                    },
                });
            },
            showToatWarning(message) {
                toast.warning(message, {
                    autoClose: 3000,
                });
            },
            showToatSuccess(message) {
                toast.success(message, {
                    autoClose: 3000,
                });
            },
            showToatInfo(message) {
                toast.info(message, {
                    autoClose: 3000,
                });
            },
            getEvenement() {
                this.loadingTable = true;
                axiosInstance
                    .get("api/evenement_user")
                    .then((response) => {
                        this.evenement = response.data.liste;
                        this.loadingTable = false;
                    })

                    .catch((error) => {
                        // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
                        this.loadingTable = false;
                    });
            },
            modifier(data) {
                console.log(data);
                this.evenementId = data.id;
                this.formDataUpdate.titre = data?.titre;

                this.formDataUpdate.debut = new Date(data?.date_debut).toISOString().split("T")[0];
                this.formDataUpdate.type = data?.type_evenement_id;
                this.formDataUpdate.fin = new Date(data?.date_fin).toISOString().split("T")[0];

                this.formDataUpdate.description = data?.description;
                this.formDataUpdate.dressCode = data?.dress_code;
                this.formDataUpdate.lieu = data?.lieu;
                this.formDataUpdate.nombre = data?.nombre_invite;
                this.formDataUpdate.entree = data?.entree;
                this.formDataUpdate.tarif = data?.tarif;
            },
            ouvrirModalSupprimerEvenement(id) {
                this.evenementId = id;
                console.log(this.evenementId);
            },
            supprimerEvenement() {
                this.loader = true;
                // Envoyer la requête de mise à jour de l'événement à votre API
                axiosInstance
                    .delete(`api/evenements/${this.evenementId}`)
                    .then((response) => {
                        this.loader = false;
                        this.fermerModalSuppression();
                        this.showToatSuccess(response.data.msg);

                        if (response.data && response.data.success) {
                            console.log("mise à jour réussie:", response.data);

                            console.log(response.data.msg);
                        }
                        this.getEvenement();
                    })
                    .catch((error) => {
                        this.loader = false;
                        this.showToatWarning("Echec de la modification de l'évènement");
                        // Gérer les erreurs de la mise à jour de l'événement
                        console.error(error);
                    });
            },

            openEditModal(evenement) {
                // Ouvrir la fenêtre modale de modification avec les données de l'événement
                this.evenementToEdit = { ...evenement };
            },
            afficherTypeEvenement() {
                axiosInstance
                    .get("api/typeevenements")
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.typesEvenements = response.data.liste;
                        } else {
                        }
                    })
                    .catch((error) => {});
            },
            fermerModal() {
                var myModalEl = document.getElementById("editEvenement");
                var modal = Modal.getInstance(myModalEl);
                modal.hide();
            },
            fermerModalSuppression() {
                var myModalEl = document.getElementById("supEvenement");
                var modal = Modal.getInstance(myModalEl);
                modal.hide();
            },

            updateEvenement() {
                if (this.formDataUpdate.entree == "payant") {
                    this.formDataUpdate.entree == "Payant";
                }

                if (this.formDataUpdate.entree == "libre") {
                    this.formDataUpdate.entree == "Libre";
                }

                this.loader = true;
                // Envoyer la requête de mise à jour de l'événement à votre API
                axiosInstance
                    .post(`api/evenements/${this.evenementId}`, this.formDataUpdate)
                    .then((response) => {
                        this.loader = false;
                        this.fermerModal();
                        this.showToatSuccess(response.data.msg);
                        if (response.data && response.data.success) {
                            console.log("mise à jour réussie:", response.data);

                            console.log(response.data.msg);
                        }
                        this.getEvenement();
                    })
                    .catch((error) => {
                        this.loader = false;
                        this.showToatWarning("Echec de la modification de l'évènement");
                        // Gérer les erreurs de la mise à jour de l'événement
                        console.error(error);
                    });
            },
        },

        mounted() {
            this.getEvenement();
            this.afficherTypeEvenement();
            const infoUser = JSON.parse(sessionStorage?.getItem("infoUser"));
            this.formDataUpdate.userId = infoUser.id;
        },
    };
</script>

<style scoped>
    .spinner-border {
        width: 3rem;
        height: 3rem;
        border-width: 0.25em;
    }
    body {
        background-color: #f6f9ff;
        font-size: 17px;
    }
    .ajout {
        background-color: #013f04;
        color: white;
    }

    .ajout:hover {
        color: #013f04;
        text-decoration: none;
        background-color: #013f04;
    }

    #main {
        margin-top: 20px;
        padding: px;
        transition: all 0.3s;
    }

    @media (max-width: 1199px) {
        #main {
            padding: 10px;
        }
    }
    .pagetitle {
        margin-bottom: 10px;
        background-color: white;
        padding: 10px;
        padding-bottom: 1px;
    }

    .pagetitle h1 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 600;
        color: #034101;
    }
    .pagetitle a {
        color: #025202a9;
        text-decoration: none;
    }
    .card {
        border: none;
    }
    .card-title {
        font-weight: bold;
    }
    .card-title h5 {
        font-size: 20px;
        color: #000918;
    }
    .card-body {
        padding: 0 20px 20px 20px;
    }
    .obligatoire {
        color: #034101;
    }
</style>
