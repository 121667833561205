<template>
    <div class="container">
        <div class="row">
            <div class="pagetitle titre">
                <nav style="--bs-breadcrumb-divider: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E')" aria-label="breadcrumb">
                    <ol class="breadcrumb m-3">
                        <li class="breadcrumb-item"><router-link to="/dashboard/liste_evenement">Liste evènemenet</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page"><router-link to="/dashboard/liste_invite2">Liste invité</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Afficher invité</li>
                    </ol>
                </nav>

                <h1 class="m-3" style="font-size: 24px; margin-bottom: 0; font-weight: 600; color: #034101">Invités</h1>
                <hr class="border-bottom" />
                <div class="row mt-2">
                    <div class="user mb-5">
                        <div class="col-md-12 m-2">
                            <p class="fs-5 float-start" style="font-weight: bold">Vos invités</p>
                            <button class="btn float-end" style="background-color: rgba(1, 87, 1, 0.945); color: white">
                                <router-link to="/dashboard/ajout_invite" class="text-white text-decoration-none"> Ajouter invité </router-link>
                            </button>
                        </div>
                        <br />

                        <div class="row mt-4">
                            <div class="card-body table-responsive">
                                <table class="table table-hover table-striped table-borderless datatable">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">N</th>
                                            <th scope="col">Code Invité</th>
                                            <th scope="col">Identité</th>
                                            <th scope="col">Tel</th>
                                            <th scope="col">Liste d'invité</th>
                                            <th scope="col">Date de création</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(invite, index) in invites" :key="index">
                                            <th scope="row">{{ index + 1 }}</th>
                                            <td>{{ invite.code }}</td>
                                            <td>{{ invite.nom_invite }}</td>
                                            <td>{{ invite.telephone_invite }}</td>
                                            <td>{{ invite.nom_liste_invite }}</td>
                                            <td>{{ formatDateString(invite.created_at) }}</td>
                                            <td class="">
                                                <a @click="setIndexToDelete(invite.id)" class="m-1" data-bs-toggle="modal" data-bs-target="#supinvitation" style="padding: 0px 5px">
                                                    <i class="bi bi-trash-fill text-danger"></i>
                                                </a>

                                                <!-- Ajout de l'invocation de la méthode editInvite avec l'ID de l'invité -->
                                                <!-- <a @click="modalEditInvite(invite)" class="m-1" data-bs-toggle="modal" data-bs-target="#editinvite" style="color: #019d0b">
                                                    <i class="bi bi-pencil-square"></i>
                                                </a> -->
                                                <a class="" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots-vertical text-success"></i>
                                                </a>

                                                <ul class="dropdown-menu">
                                                    <li>
                                                        <router-link to="" class="dropdown-item d-flex align-items-center">Détails</router-link>
                                                    </li>
                                                    <li class="dropdown-divider"></li>
                                                    <li>
                                                        <router-link to="" class="dropdown-item d-flex align-items-center">Invités Déclinés</router-link>
                                                    </li>
                                                    <li class="dropdown-divider"></li>

                                                    <li>
                                                        <router-link to="" class="dropdown-item d-flex align-items-center">Invités Confirmés</router-link>
                                                    </li>
                                                    <li class="dropdown-divider"></li>

                                                    <li>
                                                        <router-link to="" class="dropdown-item d-flex align-items-center">Invités Infirmés</router-link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-if="loadingTable" class="text-center mt-5">
                                    <!-- Loader/spinner code goes here -->
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <p>chargement...</p>
                                </div>

                                <!-- Modal pour la modification -->

                                <div class="modal fade" id="editinvite" tabindex="-1">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Modifier un invité</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <!-- Formulaire d'ajout d'invité -->
                                                <form @submit.prevent="editInvite">
                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="Nom" class="form-label">Nom</label>
                                                            <input type="text" id="nom" v-model="nom" class="form-control" placeholder="" />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="Prenom" class="form-label">Prénom</label>
                                                            <input type="text" id="prenom" v-model="prenom" class="form-control" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="email" class="form-label">E-mail</label>
                                                            <input type="email" id="email" v-model="email" class="form-control" placeholder="" />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="civilité" class="form-label">Civilité</label>
                                                            <select id="civilité" v-model="civilite" class="form-select">
                                                                <option value="Monsieur">Monsieur</option>
                                                                <option value="Madame">Madame</option>
                                                                <option value="Mademoiselle">Mademoiselle</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="date" class="form-label">Date Naissance</label>
                                                            <input type="date" id="date" v-model="date" class="form-control" placeholder=" " />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="domaine" class="form-label">Domaine Activité</label>
                                                            <input type="text" id="domaine" v-model="domaine" class="form-control" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="profession" class="form-label">Profession</label>
                                                            <input type="text" id="profession" v-model="profession" class="form-control" placeholder="" />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="titre" class="form-label">Titre</label>
                                                            <input type="text" id="titre" v-model="titre" class="form-control" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="matrimoniale" class="form-label">Situation matrimoniale</label>
                                                            <select id="matrimoniale" v-model="matrimoniale" class="form-select">
                                                                <option value="Celibataire">Célibataire</option>
                                                                <option value="Marie">Marié</option>
                                                                <option value="Divorce">Divorcé</option>
                                                                <option value="Fiance">Fiancé</option>
                                                                <option value="Veuf">Veuf</option>
                                                                <option value="Concubinage">Concubinage</option>
                                                                <option value="Couple">Couple</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6 mb-1">
                                                            <label for="adresse" class="form-label">Adresse</label>
                                                            <input type="text" id="adresse" v-model="adresse" class="form-control" placeholder="" />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="telephone" class="form-label">Numéro de téléphone</label>
                                                            <input type="number" id="telephone" v-model="telephone" class="form-control" placeholder="" />
                                                        </div>
                                                        <div class="justify-content-center col-md-6 mt-4">
                                                            <button class="btn" style="background: rgba(1, 87, 1, 0.945); color: white" type="submit">Modifier</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Modal pour la suppression -->
                                <div class="modal fade" id="supinvitation" tabindex="-1">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Suppression</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Ëtes-vous sûr de vouloir supprimer l'invitation???</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                                <a type="button" class="btn btn-danger" @click="supprimerInvite()">Supprimer</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useRoute } from "vue-router";
    import axiosInstance from "../api/axios";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    import { Modal } from "bootstrap/dist/js/bootstrap.js";

    export default {
        components: {},
        data() {
            return {
                isDropdownOpen: false,
                loadingTable: false,
                idEvenement: "",
                listeInviteId: "",
                invites: [],

                nom: "",
                prenom: "",
                email: "",
                civilite: "",
                date: "",
                domaine: "",
                profession: "",
                titre: "",
                matrimoniale: "",
                adresse: "",
                telephone: "",
                invitationId: "",
            };
        },
        mounted() {
            const route = useRoute();
            this.idEvenement = route.params.idEvenement;
            this.listeInviteId = route.params.idListe_invite;
            this.afficherliste();
        },

        methods: {
            setIndexToDelete(id) {
                this.invitationId = id;
            },

            supprimerInvite() {
                axiosInstance
                    .delete(`api/invitations/${this.invitationId}`)
                    .then((response) => {
                        if (response.data.status === 200) {
                            this.showToastSuccess(response.data.msg);

                            // Récupérer la liste mise à jour des invitations après une suppression réussie
                            this.afficherliste();
                        }
                        this.fermerModalSuppression();
                    })
                    .catch((error) => {
                        this.showToastWarning("Erreur lors de la suppression de l'invitation. Veuillez réessayer.");
                    });
            },

            // fermerModalEdit() {
            //     var myModalEl = document.getElementById("editinvite");
            //     var modal = Modal.getInstance(myModalEl);
            //     modal.hide();
            // },

            fermerModalSuppression() {
                var myModalEl = document.getElementById("supinvitation");
                var modal = Modal.getInstance(myModalEl);
                modal.hide();
            },

            // modalEditInvite(invite) {
            //     this.inviteId = invite.id;

            //     var parties = invite.nom_invite.split(" ");

            //     this.nom = parties[0];
            //     this.prenom = parties.slice(1).join(" ");
            //     this.telephone = invite.telephone_invite;
            // },

            // editInvite() {
            //     const data = {
            //         nom: this.nom,
            //         prenom: this.prenom,
            //         email: this.email,
            //         civilite: this.civilite,
            //         date_naissance: this.date_naissance,
            //         domaine: this.domaine,
            //         profession: this.profession,
            //         titre: this.titre,
            //         matrimoniale: this.matrimoniale,
            //         adresse: this.adresse,
            //         telephone: this.telephone,
            //     };

            //     console.log("ok");

            //     axiosInstance
            //         // .post(`api/evenements/${this.idEvenement}/listeinvites/${this.listeInviteId}/invites/${this.inviteId}`, data)
            //         .post(`api/invites/${this.inviteId}`, data)
            //         .then((response) => {
            //             this.fermerModalEdit();
            //             this.showToastSuccess(response.data.msg);

            //             console.log("");
            //             this.afficherliste();
            //         })
            //         .catch((error) => {
            //             this.showToastWarning("Erreur lors de la modification");
            //             console.error("Erreur lors de la requête GET pour les détails de l'invité :", error);
            //         });
            // },

            formatDateString(dateString) {
                // Convertit la chaîne de date en un objet Date
                const date = new Date(dateString);

                // Obtient les composants de la date
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");

                // Retourne la date formatée
                return `${year}-${month}-${day}`;
            },
            toggleDropdown() {
                this.isDropdownOpen = !this.isDropdownOpen;
            },
            afficherliste() {
                this.loadingTable = true;
                axiosInstance
                    .get(`api/evenements/${this.idEvenement}/listeinvites/${this.listeInviteId}/invitations`)
                    .then((response) => {
                        this.loadingTable = false;
                        if (response.data.status === 200) {
                            // Assurez-vous que la clé "liste" correspond à la structure réelle de votre réponse
                            this.invites = response.data.liste;
                        }
                    })
                    .catch((error) => {
                        this.loadingTable = false;
                        console.error("Erreur lors de la requête GET pour les listes :", error);
                        if (error.response) {
                            console.error("Réponse du serveur :", error.response.data);
                        }

                        this.loadingTable = false;
                    });
            },

            showToastWarning(message) {
                toast.warning(message, {
                    autoClose: 3000,
                });
            },
            showToastSuccess(message) {
                toast.success(message, {
                    autoClose: 3000,
                });
            },
            showToastInfo(message) {
                toast.info(message, {
                    autoClose: 3000,
                });
            },
        },
    };
</script>

<style scoped>
    .pagetitle {
        margin-top: 15px;
        margin-bottom: 10px;
        background-color: white;
        padding: 10px;
        padding-bottom: 1px;
    }

    .pagetitle a {
        color: #025202a9;
        text-decoration: none;
    }

    .liste {
        margin-top: 20px;
        width: 103%;
        background-color: white;
        margin-right: 50px;
    }
    .para0 {
        font-weight: bold;
        margin-bottom: 1px;
    }

    .user {
        border: none;
    }

    /* Style de base du bouton du dropdown */
    .dropbtn {
        color: rgba(1, 87, 1, 0.945);

        cursor: pointer;
    }

    /* Style du dropdown au survol */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Style des options du dropdown */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 150px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        padding: 9px;
        text-decoration: none;
        display: block;
        color: #333;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }
    .btn1 {
        color: rgba(1, 87, 1, 0.945);
        cursor: pointer;
    }
    .btn2 {
        color: red;
        cursor: pointer;
    }

    .card {
        border: none;
        border-radius: 5px;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    }
    .card-title {
        padding: 20px;
        font-weight: bold;
    }
    .card-title h5 {
        font-size: 20px;
        color: #000918;
    }
    .card-body {
        padding: 0 20px 20px 20px;
    }
    .obligatoire {
        color: #034101;
    }

    .ajout {
        background-color: #013f04;
        color: white;
    }

    .ajout:hover {
        color: #013f04;
        text-decoration: none;
        background-color: #013f04;
    }

    #main {
        margin-top: 20px;
        padding: px;
        transition: all 0.3s;
    }

    @media (max-width: 1199px) {
        #main {
            padding: 10px;
        }
    }
</style>
