<template>
    <div class="container">
        <div class="row">
            <div class="pagetitle titre">
                <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='%236c757d'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                    <ol class="breadcrumb m-3">
                        <li class="breadcrumb-item"><a href="#">Invité</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Liste invité</li>
                    </ol>
                </nav>

                <h1 class="m-3" style="font-size: 24px; margin-bottom: 0; font-weight: 600; color: #034101">Evènement</h1>
                <hr class="border-bottom" />
                <div class="row mt-2">
                    <div class="user mb-5">
                        <div class="col-md-12 m-2">
                            <p class="fs-5 float-start" style="font-weight: bold">Vos invités</p>
                            <button class="btn float-end" style="background-color: rgba(1, 87, 1, 0.945); color: white">
                                <router-link to="/dashboard/ajout_invite" class="text-white text-decoration-none"> Ajouter invité </router-link>
                            </button>
                        </div>
                        <br />
                        <div class="col-sm-4">
                            <div class="input-group rounded d-flex justify-content-center align-items-center">
                                <i class="bi bi-search"></i>
                                <input type="text" class="form-control rounded-left" placeholder="Rechercher..." />
                                <button class="btn rounded-right" style="background-color: rgba(1, 87, 1, 0.945); color: white">Rechercher</button>
                            </div>
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Tout les événements</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <div class="row mt-4">
                            <div class="card-body table-responsive">
                                <table class="table table-hover table-striped table-borderless datatable">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Identité</th>
                                            <th scope="col">Date Naissance</th>
                                            <th scope="col">Tel</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Civilité</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">C-KOKO1</th>
                                            <td>KOKO Bertrand</td>
                                            <td>03/10/2023</td>
                                            <td>63524789</td>
                                            <td>Akpakpa@.com</td>
                                            <td>Masculin</td>
                                            <td class="text-end">
                                                <a href="#" class="m-1" data-bs-toggle="modal" data-bs-target="#supinvite" style="padding: 0px 5px">
                                                    <i class="bi bi-trash-fill text-danger"></i>
                                                </a>
                                                <a href="#" class="m-1" data-bs-toggle="modal" data-bs-target="#editinvite" style="color: #019d0b">
                                                    <i class="bi bi-pencil-square"></i>
                                                </a>
                                                <a class="" href="m-1" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots-vertical text-success"></i>
                                                </a>

                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item d-flex align-items-center" href="">Ajouter liste d'invités</a></li>
                                                    <li class="dropdown-divider"></li>

                                                    <li><a class="dropdown-item d-flex align-items-center" href="#">Afficher listes d'inivités</a></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <!-- Modal pour la modification -->

                                <div class="modal fade" id="editinvite" tabindex="-1">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Modifier un invité</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <!-- Formulaire d'ajout d'invité -->
                                                <form @submit.prevent="register">
                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="fullIdentite" class="form-label">Identité</label>
                                                            <input type="text" id="fullIdentite" v-model="fullIdentite" required class="form-control" placeholder="" />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="phoneNumber" class="form-label">Numéro de téléphone</label>
                                                            <input type="number" id="phoneNumber" v-model="phoneNumber" required class="form-control" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="email" class="form-label">E-mail</label>
                                                            <input type="email" id="email" v-model="email" class="form-control" required placeholder="" />
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <label for="civilité" class="form-label">Civilité</label>
                                                            <select id="civilité" v-model="selectedCountry" class="form-select">
                                                                <option value="monsieur">Monsieur</option>
                                                                <option value="madame">Madame</option>
                                                                <option value="mademoiselle">Mademoiselle</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <label for="date" class="form-label">Date Naissance</label>
                                                            <input type="date" id="date" v-model="date" class="form-control" required placeholder=" " />
                                                        </div>
                                                    </div>
                                                </form>
                                                <!-- Ajoutez ici les champs et les formulaires nécessaires pour ajouter un invité -->
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                                <button type="button" class="btn btn-success">Ajouter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Modal pour la suppression -->
                                <div class="modal fade" id="supinvite" tabindex="-1">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Suppression</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Ëtes-vous sûr de vouloir supprimer ces données ???</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                                <a href="#" type="button" class="btn btn-danger">Supprimer</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    components: {},
    data() {
        return {
            isDropdownOpen: false,

            items: [
                {
                    colonne1: "N001",
                    colonne2: "02 dec 1990",
                    colonne4: " AGOKOLI Fubbienne",
                    colonne5: "51208393",
                    colonne6: "fubbien@gmail.com",
                    colonne7: "Mademoiselle",
                },
                // Ajoutez d'autres données ici
            ],
        };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
    },
};
</script>

<style scoped>
.pagetitle {
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
    padding-bottom: 1px;
}

.pagetitle a {
    color: #025202a9;
    text-decoration: none;
}

.liste {
    margin-top: 20px;
    width: 103%;
    background-color: white;
    margin-right: 50px;
}
.para0 {
    font-weight: bold;
    margin-bottom: 1px;
}

.user {
    border: none;
}

/* Style de base du bouton du dropdown */
.dropbtn {
    color: rgba(1, 87, 1, 0.945);

    cursor: pointer;
}

/* Style du dropdown au survol */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Style des options du dropdown */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    padding: 9px;
    text-decoration: none;
    display: block;
    color: #333;
}

.dropdown-content a:hover {
    background-color: #ddd;
}
.btn1 {
    color: rgba(1, 87, 1, 0.945);
    cursor: pointer;
}
.btn2 {
    color: red;
    cursor: pointer;
}

.card {
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}
.card-title {
    padding: 20px;
    font-weight: bold;
}
.card-title h5 {
    font-size: 20px;
    color: #000918;
}
.card-body {
    padding: 0 20px 20px 20px;
}
.obligatoire {
    color: #034101;
}

.ajout {
    background-color: #013f04;
    color: white;
}

.ajout:hover {
    color: #013f04;
    text-decoration: none;
    background-color: #013f04;
}

#main {
    margin-top: 20px;
    padding: px;
    transition: all 0.3s;
}

@media (max-width: 1199px) {
    #main {
        padding: 10px;
    }
}
</style>
