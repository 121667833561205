<template>
    <main id="main" class="main body">
        <div class="pagetitle titre">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='%236c757d'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                <ol class="breadcrumb m-3">
                    <li class="breadcrumb-item"><a href="#">Evènement</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Liste évènement</li>
                    <li class="breadcrumb-item active" aria-current="page">Détails</li>
                </ol>
            </nav>
            <h1 class="m-3">Détails Liste</h1>
            <hr class="border-bottom" />
            <section class="section">
                <div class="row mt-3">
                    <div class="col-12 pt-4">
                        <div class="row g-3 card-title">
                            <div class="col-12"><h5>Détails</h5></div>
                            <div class="dropdown-divider"></div>
                        </div>
                        <div class="card pb-0">
                            <div class="row g-3 pt-0 pb-0 card-title">
                                <div class="col-12"><h5>Invités recemment ajoutés</h5></div>
                            </div>

                            <div class="card-body pb-0">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped table-borderless datatable">
                                        <tbody>
                                            <tr>
                                                <td>KOKO Pierre</td>
                                                <td>00000000</td>
                                                <td>calavi</td>
                                                <td>
                                                    <a href="#">
                                                        <i class="bi bi-person-check"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <a href="#">
                                    <i class="bi bi-three-dots"></i>
                                </a>
                            </div>
                        </div>

                        <div class="card pb-0">
                            <div class="row g-3 pt-0 pb-0 card-title">
                                <div class="col-12"><h5>Invités ayant confirmés leur présence</h5></div>
                            </div>

                            <div class="card-body pb-0">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped table-borderless datatable">
                                        <tbody>
                                            <tr>
                                                <td>KOKO Pierre</td>
                                                <td>00000000</td>
                                                <td>calavi</td>
                                                <td>
                                                    <a href="#">
                                                        <i class="bi bi-person-check"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <a href="listeInviteConfirmAll.php">
                                    <i class="bi bi-three-dots"></i>
                                </a>
                            </div>
                        </div>

                        <div class="card pb-0">
                            <div class="row g-3 pt-0 pb-0 card-title">
                                <div class="col-12"><h5>Invités ayant infirmés leur présence</h5></div>
                            </div>

                            <div class="card-body pb-0">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped table-borderless datatable">
                                        <tbody>
                                            <tr>
                                                <td>KOKO Pierre</td>
                                                <td>00000000</td>
                                                <td>calavi</td>
                                                <td>
                                                    <a href="#">
                                                        <i class="bi bi-person-check"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <a href="listeInviteInfirmAll.php">
                                    <i class="bi bi-three-dots"></i>
                                </a>
                            </div>
                        </div>

                        <div class="card pb-0">
                            <div class="row g-3 pt-0 pb-0 card-title">
                                <div class="col-12"><h5>Invités ayant déclinés leur présence</h5></div>
                            </div>

                            <div class="card-body pb-0">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped table-borderless datatable">
                                        <tbody>
                                            <tr>
                                                <td>KOKO Pierre</td>
                                                <td>00000000</td>
                                                <td>calavi</td>
                                                <td>
                                                    <a href="#">
                                                        <i class="bi bi-person-check"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <a href="listeInviteDeclineAll.php">
                                    <i class="bi bi-three-dots"></i>
                                </a>
                            </div>
                        </div>

                        <div class="mb-2 text-center d-flex justify-content-between">
                            <div>
                                <router-link to="/dashboard/ajout_liste" class="btn btn-outline-primary"> Ajouter invité </router-link>
                            </div>
                            <div>
                                <router-link :to="'/dashboard/liste_invite2/' + idEvenement" class="btn btn-outline-secondary"> Liste d'invité </router-link>
                            </div>
                            <div>
                                <router-link type="button" :to="'/dashboard/liste_presence/' + idEvenement" class="btn btn-outline-success">Liste de présence</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
    import Vue from "vue";
    import { useRoute } from "vue-router";
    import axiosInstance from "../api/axios";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    import { Modal } from "bootstrap/dist/js/bootstrap.js";

    import VueRouter from "vue-router";
    export default {
        data() {
            return {
                idEvenement: "",
                detail: {},
                detailTypeEvenement: {},
            };
        },
        methods: {
            getDetail() {
                axiosInstance
                    .get(`api/evenements/${this.idEvenement}/listeinvites/${this.idEvenement}`)
                    .then((response) => {
                        this.detail = response.data.info;
                        this.getDetailTypeEvenement();
                    })
                    .catch((error) => {
                        // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
                    });
            },
            //typeevenements/{typeevenement}
            // getDetailTypeEvenement() {
            //     axiosInstance
            //         .get(`api/typeevenements/${this.detail.type_evenement_id}`)
            //         .then((response) => {
            //             this.detailTypeEvenement = response.data.info;
            //             console.log(this.detailTypeEvenement);
            //         })
            //         .catch((error) => {
            //             // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
            //         });
            // },
            // getInviteConfirme() {
            //     axiosInstance
            //         .get(`api/typeevenements/${this.detail.type_evenement_id}`)
            //         .then((response) => {
            //             this.detailTypeEvenement = response.data.info;
            //             console.log(this.detailTypeEvenement);
            //         })
            //         .catch((error) => {
            //             // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
            //         });
            // },
        },
        mounted() {
            const route = useRoute();
            this.idEvenement = route.params.idEvenement;
            this.getDetail();
        },
    };
</script>

<style scoped>
    body {
        background-color: #f6f9ff;
        font-size: 17px;
    }

    a {
        color: #034101;
        text-decoration: none;
    }

    a:hover {
        color: #010113;
        text-decoration: none;
    }

    #main {
        margin-top: 2px;
        padding: 20px 30px;
        transition: all 0.3s;
    }

    @media (max-width: 1199px) {
        #main {
            padding: 20px;
        }
    }
    .pagetitle {
        margin-bottom: 10px;
        background-color: white;
        padding: 10px;
        padding-bottom: 1px;
    }

    .pagetitle h1 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 600;
        color: #034101;
    }
    .pagetitle a {
        color: #025202a9;
    }
    .card {
        border: none;
    }
    .card-title {
        padding: 20px;
        font-weight: bold;
    }
    .card-title h5 {
        font-size: 20px;
        color: #000918;
    }
    .card-body {
        padding: 0 20px 20px 20px;
    }
    .obligatoire {
        color: #034101;
    }
</style>
