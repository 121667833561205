<template>
    <div class="min-vh-100 d-flex align-items-center position-relative justify-content-center background">
        <svg width="64px" height="96px" class="svg position-absolute start-50 translate-middle-x" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- Contenu de votre SVG -->
        </svg>

        <div class="backdrop-blur-sm bg-white shadow-lg p-4 rounded box-border connexion">
            <form @submit.prevent="requestPasswordReset">
                <div class="mb-4">
                    <label for="email" class="form-label">Entrer votre E-mail<span class="text-success">*</span></label>
                    <input type="email" class="form-control" required id="fullemail" name="email" v-model="email" placeholder="dodo@gmail.com" />
                </div>
                <div class="d-flex justify-content-between">
                    <button type="submit" class="btn d-flex justify-content-center align-items-center mb-4" style="background-color: #013f04; color: white">
                        <span style="text-decoration: none; color: white"> Envoyer</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
    data() {
        return {
            email: "",
        };
    },
    methods: {
        showToatWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToatSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToatInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },

        requestPasswordReset() {
            const userData = {
                email: this.email,
            };
            axiosInstance
                .post("api/forgotPassword", userData)
                .then((response) => {
                    this.showToatSuccess("veuillez consulté votre mail pour la suite des opérations");
                    setTimeout(() => {
                        this.$router.push("/");
                    }, 3000);
                })
                .catch((error) => {
                    this.showToatWarning("Echec lors de l'envoie de l'email !!!");
                });
        },
    },
};
</script>

<style scoped>
.background {
    background-color: #f6f9ff;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
}

input:focus,
textarea:focus,
select:focus,
a:focus {
    outline: none;
}

.shadow {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.connexion {
    width: 40%;
    box-shadow: black;
}

.svg {
    margin-bottom: 350px;
    width: 15%;
    height: 15%;
}
</style>
