<template>
  <div class="container">
    <div class="row">
      <div class="pagetitle titre">
        <nav style="--bs-breadcrumb-divider: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E');" aria-label="breadcrumb">
          <ol class="breadcrumb m-3">
            <li class="breadcrumb-item"><a href="#">Invité</a></li>
            <li class="breadcrumb-item active" aria-current="page">Mes invités</li>
            <li class="breadcrumb-item active" aria-current="page">Ajouté Liste</li>
          </ol>
        </nav>

        <div>
          <h1 class="m-3" style="font-size: 24px; margin-bottom: 0; font-weight: 600; color: #034101;">Liste invité</h1>
          <div class="mr-3">
            <hr class="border-bottom">
          </div>
        </div>

        <div class="row mt-3">

          <div class="user shadow">

            <div class="row m-3">
              <label for="libelle" class="form-label"><strong>Choisir un évènement</strong> <span class="obligatoire">*</span></label>
              <div class="col-md-6 form-floating">
                <select class="form-select w-50" id="type" aria-label="type" required name="type" v-model="selectedEvent" @change="showAddListForm">
                  <option disabled value="">Veuillez choisir un évènement</option>
                  <!-- Utilisez "titre" comme clé et valeur pour chaque option -->
                  <option v-for="event in events" :key="event.titre" :value="event.id">{{ event.titre }}</option>
                </select>
              </div>
              <div class="col-md-6 mt-3">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#ajoutliste"
                  class="text-white p-2"
                  style="background-color: #034101;"
                  :class="{ 'disabled-link': !selectedEvent }"
                >
                  Ajout liste
                </a>
              </div>
            </div>

            <div class="modal fade" ref="modalRef" id="ajoutliste" tabindex="-1">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <div v-if="selectedEvent && displayAddListForm">
                      <p class="para1 fs-4 m-2">Ajout Liste Invité</p>
                      <p class="para2 fs-5">Nom événement</p>
                      <h5 class="modal-title">Ajouter un nouveau type d'événement</h5>
                    </div> <br>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form class="mt-6" @submit.prevent="submitForm">
                      <div class="row ml-3 mr-3 mt-2">
                        <div class="col-md-6 mb-4">
                          <label for="nom" class="form-label">Nom liste</label>
                          <input v-model="formData.nom" type="text" id="nom" class="form-control" required placeholder="">
                        </div>
                        <div class="col-md-6 mb-4">
                          <label for="categorie" class="form-label">Catégorie</label>
                          <select v-model="formData.categorie" id="categorie" class="form-select" required>
                            <option value="VIP">VIP</option>
                            <option value="Standard">Standard</option>
                          </select>
                        </div>
                      </div>
                      <div class="row ml-3 mr-3 mt-2">
                        <div class="col-md-6 mb-4">
                          <label for="message" class="form-label">Message</label>
                          <textarea v-model="formData.message" id="message" class="form-control" rows="3" placeholder="Insérer votre texte ici"></textarea>
                        </div>
                        <div class="col-md-6 d-flex justify-content-between align-items-center">
                          <button class="btn mt-2" type="submit" style="background-color: rgba(1, 87, 1, 0.945); color: white; width: 25">Ajouter</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body table-responsive mt-3">
              <table class="table table-hover table-striped table-borderless datatable">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Date</th>
                    <th scope="col">Nom Liste</th>
                    <th scope="col">Catégorie</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(liste, index) in listeAjoute" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ formatDateString(liste.created_at) }}</td>
                    <td>{{ liste.nom }}</td>
                    <td>{{ liste.categorie }}</td>
                    <td class="">
                    <a @click="setIndexToDelete(index)" href="#" class="m-1" data-bs-toggle="modal" data-bs-target="#supEvenement" style="padding: 0px 5px">
                      <i class="bi bi-trash-fill text-danger"></i>
                    </a>

                      <a  @click="setIndexToDelete(index)" href="#" class="m-1" data-bs-toggle="modal" data-bs-target="#edit"  style="color: #019d0b">
                        <i class="bi bi-pencil-square"></i>
                      </a>
                      <a class="" href="m-1" data-bs-toggle="dropdown">
                        <i class="bi bi-three-dots-vertical text-success"></i>
                      </a>

                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item d-flex align-items-center" href="">Détails</a></li>
                        <li class="dropdown-divider"></li>

                        <li><a class="dropdown-item d-flex align-items-center" href="">Ajouter liste d'invités</a></li>
                        <li class="dropdown-divider"></li>

                        <li><a class="dropdown-item d-flex align-items-center" href="#">Afficher listes d'inivités</a></li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>

    <div class="modal fade" id="supEvenement" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Suppression</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Êtes-vous sûr de vouloir supprimer cette liste d'invités ???</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
            <button type="button" class="btn btn-danger" @click="supprimerListe">Supprimer</button>
          </div>
    </div>
  </div>
</div>
 <div class="modal fade" id="edit" tabindex="-1"  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Modifier la liste</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <form class="m-3" @submit.prevent="modifierListe">
                      <div class="row ml-3 mr-3 mt-2">
                        <div class="col-md-6 mb-4">
                          <label for="nom" class="form-label">Nom liste</label>
                          <input v-model="formData.nom" type="text" id="nom" class="form-control" required placeholder="">
                        </div>
                        <div class="col-md-6 mb-4">
                          <label for="categorie" class="form-label">Catégorie</label>
                          <select v-model="formData.categorie" id="categorie" class="form-select" required>
                            <option value="VIP">VIP</option>
                            <option value="Standard">Standard</option>
                          </select>
                        </div>
                      </div>
                      <div class="row ml-3 mr-3 mt-2">
                        <div class="col-md-6 mb-4">
                          <label for="message" class="form-label">Message</label>
                          <textarea v-model="formData.message" id="message" class="form-control" rows="3" placeholder="Insérer votre texte ici"></textarea>
                        </div>
                        <div class="col-md-6 d-flex justify-content-between align-items-center">
                          <button class="btn mt-2" type="submit" style="background-color: rgba(1, 87, 1, 0.945); color: white; width: 25">Ajouter</button>
                        </div>
                      </div>
                    </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       
      </div>
    </div>
  </div>
</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import axiosInstance from '../api/axios';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  data() {
    return {
       indexToDelete: null,
      formData: {
        nom: '',
        categorie: 'VIP',
        message: '',
      },
      selectedEvent: null,
      events: [],
      displayAddListForm: false,
      listeAjoute: [],
    };
  },
  methods: {
    formatDateString(dateString) {
      // Convertit la chaîne de date en un objet Date
      const date = new Date(dateString);

      // Obtient les composants de la date
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      // Retourne la date formatée
      return `${year}-${month}-${day}`;
    },
    getEvenement() {
      axiosInstance
        .get("api/evenement_user")
        .then((response) => {
          this.events = response.data.liste;
        })
        .catch((error) => {
          // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
        });
    },
    showToastWarning(message) {
      toast.warning(message, {
        autoClose: 3000,
      });
    },
    showToastSuccess(message) {
      toast.success(message, {
        autoClose: 3000,
      });
    },
    showToastInfo(message) {
      toast.info(message, {
        autoClose: 3000,
      });
    },
    showAddListForm() {
      if (this.selectedEvent) {
        this.afficherliste(); // Appel de la méthode pour afficher les listes au choix de l'événement
        this.displayAddListForm = true;
      }
    },
    submitForm() {
      if (!this.selectedEvent) {
        this.showToastWarning("Veuillez choisir un évènement.");
        return;
      }

      const eventData = {
        event_id: this.selectedEvent,
        nom: this.formData.nom,
        categorie: this.formData.categorie,
        message: this.formData.message,
      };

      axiosInstance
        .post(`api/evenements/${this.selectedEvent}/listeinvites`, eventData)
        .then((response) => {

          console.log("liste ajoutée",response.data)
          if(response.data.status===200){
              this.showToastSuccess(response.data.msg);
            this.displayAddListForm = false;
            this.formData = {
            nom: '',
            categorie: 'VIP',
            message: '',
          };
          this.afficherliste();
        }else{
            this.showToastWarning(response.data.msg);
        }
          
        })
        .catch((error) => {
           console.error('Erreur lors de la requête POST :', error);
           if (error.response) {
           console.error('Réponse du serveur :', error.response.data);
           }
           this.showToastWarning("Erreur lors de l'ajout de la liste. Veuillez vérifier les détails de l'événement.");
        });
    },

     setIndexToDelete(index) {
      this.indexToDelete = index;
    },

    supprimerListe() {
      const index = this.indexToDelete;
      if (index !== null) {
        const listeASupprimer = this.listeAjoute[index];

        axiosInstance.delete(`api/evenements/${this.selectedEvent}/listeinvites/${listeASupprimer.id}`)
          .then(response => {
            this.afficherliste(); // Rafraîchir la liste après suppression
            this.showToastSuccess('Liste d\'invités supprimée avec succès');
          })
          .catch(error => {
            console.error('Erreur lors de la suppression de la liste d\'invités :', error);
            this.showToastWarning('Erreur lors de la suppression de la liste d\'invités');
          });

        this.indexToDelete = null; // Réinitialiser l'index après la suppression
      } else {
        console.error('Aucun index à supprimer n\'a été défini.');
      }
    },
 

    afficherliste() {
      axiosInstance
        .get(`api/evenements/${this.selectedEvent}/listeinvites`)
        .then((response) => {
          if(response.data.status===200){
               this.showToastSuccess("Liste récupérée avec succès");
          console.log("Liste récupérée avec succès", response.data);

          // Assurez-vous que la clé "liste" correspond à la structure réelle de votre réponse
          this.listeAjoute = response.data.liste;
          }else{
            this.showToastWarning(response.data.msg);
          }
           
        })
        .catch((error) => {
          console.error('Erreur lors de la requête GET pour les listes :', error);
          if (error.response) {
            console.error('Réponse du serveur :', error.response.data);
          }
          this.showToastWarning("Erreur lors de la récupération des listes. Veuillez réessayer.");
        });
    },

    
    modifierListe() {
  const index = this.indexToDelete;
  if (index !== null) {
    const listeAModifier = this.listeAjoute[index];

    const listeModifiee = {
      nom: this.formData.nom,
      categorie: this.formData.categorie,
      message: this.formData.message,
    };

    axiosInstance
      .post(`api/evenements/${this.selectedEvent}/listeinvites/${listeAModifier.id}`, listeModifiee)
      .then(response => {
        this.afficherliste(); // Actualiser la liste après la modification
        this.showToastSuccess(response.data.msg);
        this.indexToDelete = null; // Réinitialiser l'index après la modification
      })
      .catch(error => {
        console.error('Erreur lors de la modification de la liste d\'invités :', error);
        this.showToastWarning('Erreur lors de la modification de la liste d\'invités');
      });
  } else {
    console.error('Aucun index à modifier n\'a été défini.');
  }
},

  },
 


  mounted() {
    const route = useRoute();
    this.selectedEvent = route.params.selectedEvent;
    this.getEvenement();
  },
  computed: {
    linkClass() {
      return {
        'disabled-link': !this.selectedEvent,
      };
    },
  },
};
</script>

<style scoped>
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.pagetitle {
  margin-top: 15px;
  background-color: white;
  padding: 10px;
  padding-bottom: 1px;
}

.pagetitle a {
  color: #025202a9;
  text-decoration: none;
}

.user {
  border: none;
}

.para1 {
  font-weight: bold;
  border-left: 5px solid rgb(21, 145, 42);
}

.para2 {
  font-weight: bold;
  color: rgba(1, 87, 1, 0.945);
}

input, select, textarea {
  background-color: #f6f9ff;
}
</style>
