<template>
    <div class="container">
        <div class="row">
            <div class="pagetitle titre">
                <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='%236c757d'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                    <ol class="breadcrumb m-3">
                        <li class="breadcrumb-item active" aria-current="page"><router-link to="/dashboard/liste_evenement">Liste evènement</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page"><router-link to="/dashboard/liste_invite2"> Liste invité</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Ajouter invité</li>
                    </ol>
                </nav>

                <h1 class="m-3">Evènement</h1>
                <hr class="border-bottom" />

                <div class="row mt-2">
                    <div class="user">
                        <div class="col-md-12 m-2">
                            <p class="fs-4 float-start" style="font-weight: bold; border-left: 5px solid rgb(21, 145, 42)">Ajout Invité</p>
                            <button class="btn float-end" style="background-color: rgba(1, 87, 1, 0.945); color: white">
                                <router-link to="/dashboard/afficher_invite" class="text-white text-decoration-none"> Afficher invité </router-link>
                            </button>
                        </div>
                        <br />
                        <div class="col-md-12 mt-5">
                            <form @submit.prevent="ajout">
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="Nom" class="form-label">Nom</label>
                                        <input type="text" id="nom" v-model="nom" required class="form-control" placeholder="" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="Prenom" class="form-label">Prénom</label>
                                        <input type="text" id="prenom" v-model="prenom" required class="form-control" placeholder="" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="email" class="form-label">E-mail</label>
                                        <input type="email" id="email" v-model="email" class="form-control" placeholder="" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="civilité" class="form-label">Civilité</label>
                                        <select id="civilité" v-model="civilite" class="form-select">
                                            <option value="Monsieur">Monsieur</option>
                                            <option value="Madame">Madame</option>
                                            <option value="Mademoiselle">Mademoiselle</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="date" class="form-label">Date Naissance</label>
                                        <input type="date" id="date" v-model="date" class="form-control" placeholder=" " />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="domaine" class="form-label">Domaine Activité</label>
                                        <input type="text" id="domaine" v-model="domaine" class="form-control" placeholder="" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="profession" class="form-label">Profession</label>
                                        <input type="text" id="profession" v-model="profession" class="form-control" placeholder="" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="titre" class="form-label">Titre</label>
                                        <input type="text" id="titre" v-model="titre" class="form-control" placeholder="" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="matrimoniale" class="form-label">Situation matrimoniale</label>
                                        <select id="matrimoniale" v-model="matrimoniale" class="form-select">
                                            <option value="Celibataire">Célibataire</option>
                                            <option value="Marie">Marié</option>
                                            <option value="Divorce">Divorcé</option>
                                            <option value="Fiance">Fiancé</option>
                                            <option value="Veuf">Veuf</option>
                                            <option value="Concubinage">Concubinage</option>
                                            <option value="Couple">Couple</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <label for="adresse" class="form-label">Adresse</label>
                                        <input type="text" id="adresse" v-model="adresse" class="form-control" placeholder="" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="telephone" class="form-label">Numéro de téléphone</label>
                                        <input type="number" id="telephone" v-model="telephone" required class="form-control" placeholder="" />
                                    </div>
                                    <div class="justify-content-center col-md-6 mt-4">
                                        <button class="btn" style="background: rgba(1, 87, 1, 0.945); color: white" type="submit">Ajouter</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from "vue-router";
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
    data() {
        return {
            idListe_invite: null,
            idEvenement: null,
            nom: "",
            prenom: "",
            email: "",
            civilite: "",
            date: "",
            domaine: "",
            profession: "",
            titre: "",
            matrimoniale: "",
            adresse: "",
            telephone: "",
        };
    },
    mounted() {
        const route = useRoute();
        this.idEvenement = this.$route.params.idEvenement;
        this.idListe_invite = this.$route.params.idListe_invite;
        console.log(this.idEvenement);
    },

    methods: {
        ajout() {
            const formData = {
                nom: this.nom,
                prenom: this.prenom,
                email: this.email,
                civilite: this.civilite,
                date: this.date,
                domaine: this.domaine,
                profession: this.profession,
                titre: this.titre,
                matrimoniale: this.matrimoniale,
                adresse: this.adresse,
                telephone: this.telephone,
            };

            axiosInstance
                .post(`api/evenements/${this.idEvenement}/listeinvites/${this.idListe_invite}/invites`, formData)

                .then((response) => {
                    console.log("ajout reussi");
                    this.showToastSuccess(response.data.msg);

                    formData.nom = this.nom;
                    formData.prenom = "";
                    formData.email = "";
                    formData.civilite = "";
                    formData.date = "";
                    formData.domaine = "";
                    formData.profession = "";
                    formData.titre = "";
                    formData.matrimoniale = "";
                    formData.adresse = "";
                    formData.telephone = "";
                    // Gérer la suite de la logique ou rediriger l'utilisateur si nécessaire
                })
                .catch((error) => {
                    console.error(error);
                    this.showToastWarning("Échec de l'ajout d'invité");
                });
        },
        showToastWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToastSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToastInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },
    },
};
</script>

<style scoped>
.pagetitle {
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
    padding-bottom: 1px;
}

.pagetitle h1 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 600;
    color: #034101;
}
.pagetitle a {
    color: #025202a9;
    text-decoration: none;
}

.liste {
    margin-top: 20px;
    width: 103%;
    background-color: white;
    margin-right: 50px;
}
.para0 {
    font-weight: bold;
    margin-bottom: 1px;
}

.user {
    border: none;
}

.bouton {
    color: rgba(1, 87, 1, 0.945);
    border-color: rgba(1, 87, 1, 0.945);
}
.bouton:hover {
    background-color: rgba(1, 87, 1, 0.945);
    color: white;
}
input {
    background-color: #f6f9ff;
}

select {
    background-color: #f6f9ff;
}
</style>
