<template>
    <div class="container">
        <div class="">
            <div class="pagetitle titre">
                <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='%236c757d'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                    <ol class="breadcrumb m-3">
                        <li class="breadcrumb-item"><a>Invité</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Mes invités</li>
                        <li class="breadcrumb-item active" aria-current="page">Liste</li>
                    </ol>
                </nav>

                <h1 class="m-3">Ensembles des listes d'invités</h1>
                <hr class="border-bottom" />
                <div class="mt-2">
                    <section class="section">
                        <div class="card">
                            <div class="row g-3 card-title">
                              
                                <div class="">
                                    <router-link to="/dashboard/ajout_liste" class="text-white text-decoration-none btn ajout float-end fw-bold fs-6"> <i class="bi bi-plus-circle"></i> Ajouter Liste</router-link>
                                </div>
                            </div>
                            <div class="card-body table-responsive">
                                <table class="table table-hover table-striped table-borderless datatable">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Nom Liste</th>
                                            <th scope="col">Catégorie</th>

                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">C-KOKO1</th>
                                            <td>12/12/2023</td>
                                            <td>VIP</td>
                                            <td>A</td>
                                            <td class="">
                                                <a href="#" class="m-1" data-bs-toggle="modal" data-bs-target="#supEvenement" style="padding: 0px 5px">
                                                    <i class="bi bi-trash-fill text-danger"></i>
                                                </a>
                                                <a href="#" class="m-1" data-bs-toggle="offcanvas" data-bs-target="#editEvenement" aria-controls="offcanvasRight" style="color: #019d0b">
                                                    <i class="bi bi-pencil-square"></i>
                                                </a>
                                                <a class="" href="m-1" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots-vertical text-success"></i>
                                                </a>

                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item d-flex align-items-center" href="detailsEvenement.php">Détails</a></li>
                                                    <li class="dropdown-divider"></li>

                                                    <li><a class="dropdown-item d-flex align-items-center" href="listeListeInvite.php">Ajouter liste d'invités</a></li>
                                                    <li class="dropdown-divider"></li>

                                                    <li><a class="dropdown-item d-flex align-items-center" href="#">Afficher listes d'inivités</a></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="modal fade" id="supEvenement" tabindex="-1">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Suppression</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Ëtes-vous sûr de vouloir supprimer ces données ???</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                                <a href="#" type="button" class="btn btn-danger">Supprimer</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from "../api/axios";

export default {
    
    data() {
        return {
           
        };
    },
    mounted() {
      
    },
    methods: {
       
     
       
    },
};
</script>

<style scoped>
.pagetitle {
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
    padding-bottom: 1px;
}

.pagetitle h1 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 600;
    color: #034101;
}
.pagetitle a {
    color: #025202a9;
    text-decoration: none;
}

.liste {
    margin-top: 20px;
    width: 103%;
    background-color: white;
    margin-right: 50px;
}
.para0 {
    font-weight: bold;
    margin-bottom: 1px;
}

.user {
    border: none;
}

/* Style de base du bouton du dropdown */
.dropbtn {
    color: rgba(1, 87, 1, 0.945);

    cursor: pointer;
}

/* Style du dropdown au survol */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Style des options du dropdown */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    padding: 9px;
    text-decoration: none;
    display: block;
    color: #333;
}

.dropdown-content a:hover {
    background-color: #ddd;
}
.btn1 {
    color: rgba(1, 87, 1, 0.945);
    cursor: pointer;
}
.btn2 {
    color: red;
    cursor: pointer;
}

.card {
    border: none;
}
.card-title {
    padding: 20px;
    font-weight: bold;
}
.card-title h5 {
    font-size: 20px;
    color: #000918;
}
.card-body {
    padding: 0 20px 20px 20px;
}
.obligatoire {
    color: #034101;
}
.ajout {
    background-color: #013f04;
    color: white;
}

.ajout:hover {
    color: #013f04;
    text-decoration: none;
    background-color: #013f04;
}

#main {
    margin-top: 20px;
    padding: px;
    transition: all 0.3s;
}

@media (max-width: 1199px) {
    #main {
        padding: 10px;
    }
}
</style>
