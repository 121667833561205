<template>
    <div class="min-vh-100 d-flex align-items-center position-relative justify-content-center background">
        <svg width="64px" height="96px" class="svg position-absolute start-50 translate-middle-x" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- Contenu de votre SVG -->
        </svg>

        <div class="backdrop-blur-sm bg-white shadow-lg p-4 rounded box-border connexion">
            <form @submit.prevent="login">
                <div class="mb-4">
                    <label for="email" class="form-label">E-mail<span class="text-success">*</span></label>
                    <input type="email" class="form-control" required id="fullemail" name="email" v-model="email" placeholder="dodo@gmail.com" />
                     <div v-if="formErrors.email" class="text-danger">{{ formErrors.email }}</div>
                </div>
                <div class="mb-4">
                    <label for="password">Mot de passe<span class="text-success">*</span></label>
                    <input type="password" class="form-control" id="password" v-model="password" placeholder="8 caractères minimum" />
                      <div v-if="formErrors.password" class="text-danger">{{ formErrors.password }}</div>
                </div>
                <div class="d-flex flex-column flex-sm-row justify-content-between">
                  <button type="submit" class="btn d-flex justify-content-center align-items-center mb-4" :disabled="isLoading" style="background-color: #013f04;">
                    <span v-if="isLoading">Chargement...</span>
                    <span v-else class="text-white">Se connecter</span>
                    </button>
                    <div class="mt-2 fs-6">
                        <router-link to="/mot_passe" style="text-decoration:none; color: #013f04">Mot de passe oublié ?? </router-link>
                    </div>
                </div>
                <p class="text-xs text-center">
                    Vous n'avez pas de compte ? <span class="" style="text-decoration:none; color: #013f04"><router-link to="/inscription">Inscrivez-vous !!!</router-link></span>
                </p>
            </form>

          
        </div>
    </div>
</template>
<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";



export default {
    data() {
        return {
            isLoading: false,
            email: "", // Utilisez "email"
            password: "",
           
            info: null,
              formErrors: {
        email: "",
        password: "",
      },
        };
    },
    methods: {
        showToatWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToatSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToatInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },
       login() {
    this.isLoading = true;
    const userData = {
        email: this.email,
        password: this.password,
    };

    axiosInstance
        .post("api/login", userData)
        .then((response) => {
            if (response.data.status===422) {
                console.log("Response:", response.data.msg);
                const errors = response.data.msg;
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        this.formErrors[key] = errors[key][0];
                    }
                }
                this.showToatWarning(response.data.msg);
            } else {
                const token = response.data.token;
                sessionStorage.setItem("infoUser", JSON.stringify(response.data.infos));
                sessionStorage.setItem("authToken", token);
                this.showToatSuccess(response.data.msg);
                setTimeout(() => {
                    // Redirection vers le dashboard après le toast
                    this.$router.push("/dashboard/profil/");
                }, 3000);

                
            }
        })
        .finally(() => {
            this.isLoading = false;
        })
        .catch((error) => {
            console.log(error);
            this.showToatWarning("Echec de la connexion!!!");
            this.isLoading = false;
        });
},
    },
//    

};

</script>

<style scoped>
.background {
    background-color: #f6f9ff;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
}

input:focus,
textarea:focus,
select:focus,
a:focus {
    outline: none;
}

.shadow {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.connexion {
    width: 100%; /* Updated to full width */
    max-width: 400px; /* Set a maximum width */
    margin: 0 auto; /* Center the form on large screens */
}

.svg {
    margin-bottom: 20px; /* Adjusted margin for smaller screens */
    width: 64px;
    height: 96px;
}
</style>