<template>
    <div class="container-fluid">
        <div class="row">
            <!-- Colonne de la barre latérale -->
            <div class="col-lg-4 col-md-12 vh-100 text-center d-none d-lg-flex justify-content-center align-items-center vert">
                <div class="contenue">
                    <p class="text-white font-weight-bold fs-1">Soyez les bienvenus</p>
                    <div class="text-white font-arial text-sm">
                        <p>Veuillez renseigner vos informations personnelles pour vous inscrire</p>
                        <p class="text-xl font-serif">Vous avez déjà un compte ?</p>
                        <router-link to="/" class="btn btn rounded-pill text-white shadow-lg border bouton1">Se connecter</router-link>
                    </div>
                </div>
            </div>

            <!-- Colonne du formulaire -->
            <div class="col-lg-8 col-md-12 vh-100 d-lg-flex justify-content-center align-items-center compte" id="compte">
                <div class="row">
                    <div class="text-center ml-2">
                        <p class="p1 fs-2">Créer votre compte</p>
                    </div>
                    <div class="">
                        <Form @submit="register" class="mx-5 mt-2">
                            <div class="row">
                                <div class="col-md-6 mb-4">
                                    <label for="fullName" class="form-label">Nom<span class="text-success font-weight-bold">*</span></label>
                                    <div class="form-floating">
                                        <Field type="text" :rules="validateNom" class="form-control" name="nom" minlength="3" maxlength="50" v-model="formData.fullName" required id="fullName" />
                                        <ErrorMessage class="text-danger mt-1" name="nom" />
                                        <label for="fullName">Nom</label>
                                    </div>
                                    <!-- <span v-if="errors.fullName" class="alert alert-danger">{{ errors.fullName }}</span> -->
                                </div>
                                <div class="col-md-6 mb-4">
                                    <label for="fullPrenom" class="form-label">Prénom<span class="text-success font-weight-bold">*</span></label>
                                    <div class="form-floating">
                                        <Field type="text" :rules="validatePrenom" class="form-control" name="prenom" minlength="3" maxlength="50" v-model="formData.fullPrenom" required id="fullPrenom" />
                                        <ErrorMessage class="text-danger mt-1" name="prenom" />
                                        <label for="fullPrenom">Prénom</label>
                                    </div>
                                    <!-- <span v-if="errors.fullPrenom" class="alert alert-danger">{{ errors.fullPrenom }}</span> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-4">
                                    <label for="phoneNumber" class="form-label">Numéro de téléphone<span class="text-success font-weight-bold">*</span></label>
                                    <div class="form-floating">
                                        <!-- @input="validatePhoneNumber" -->
                                        <Field type="text" class="form-control" name="telephone" v-model="formData.phoneNumber" required id="fulltelephone" minlength="8" maxlength="8" pattern="[0-9]{8}" title="Entrez un numéro de téléphone valide (8 chiffres)" :rules="validatePhoneNumber" />
                                        <ErrorMessage class="text-danger mt-1" name="telephone" />
                                        <label for="fulltelephone">Téléphone</label>
                                    </div>
                                    <!-- <span v-if="errors.phoneNumber" class="alert alert-danger">{{ errors.phoneNumber }}</span> -->
                                </div>

                                <div class="col-md-6 mb-4">
                                    <label for="fullnaissance" class="form-label">Date de naissance<span class="text-success font-weight-bold">*</span></label>
                                    <div class="form-floating">
                                        <Field type="date" :rules="validateDate" class="form-control" name="date" v-model="formData.dateOfBirth" required id="fullnaissance" />
                                        <ErrorMessage class="text-danger mt-1" name="date" />
                                        <label for="fullnaissance">Date de naissance</label>
                                    </div>
                                    <!-- <span v-if="errors.dateOfBirth" class="alert alert-danger">{{ errors.dateOfBirth }}</span> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-4">
                                    <label for="password" class="form-label">Mot de passe<span class="text-success font-weight-bold">*</span></label>
                                    <div class="form-floating">
                                        <Field type="password" :rules="validatePassword" class="form-control" name="password" v-model="formData.password" required id="fullmotpasse" />
                                        <ErrorMessage class="text-danger mt-1" name="password" />
                                        <label for="fullmotpasse">Mot de passe</label>
                                    </div>
                                    <!-- <span v-if="errors.password" class="alert alert-danger">{{ errors.password }}</span> -->
                                </div>
                                <div class="col-md-6 mb-4">
                                    <label for="password_confirmation" class="form-label">Confirmer mot de passe<span class="text-success font-weight-bold">*</span></label>
                                    <div class="form-floating">
                                        <Field type="password" :rules="validatePasswordConfirmation" class="form-control" name="passwordConfirmation" v-model="formData.password_confirmation" required id="fullconfirmation" />
                                        <ErrorMessage class="text-danger mt-1" name="passwordConfirmation" />
                                        <label for="fullconfirmation">Confirmer le mot de passe</label>
                                    </div>
                                    <!-- <span v-if="errors.password_confirmation" class="alert alert-danger">{{ errors.password_confirmation }}</span> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-4">
                                    <label for="adresse" class="form-label">Adresse</label>
                                    <div class="form-floating">
                                        <Field :rules="validateAdresse" type="text" class="form-control" id="fulladresse" name="adresse" v-model="formData.adresse" />
                                        <ErrorMessage class="text-danger mt-1" name="adresse" />
                                        <label for="fulladresse">Adresse</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <label for="civilite" class="form-label">Civilité</label>
                                    <div class="form-floating">
                                        <Field id="civilite" :rules="validateCivilite" as="select" v-model="formData.civilite" class="form-control" name="civilite">
                                            <option value="Monsieur">Monsieur</option>
                                            <option value="Madame">Madame</option>
                                            <option value="Mademoiselle">Mademoiselle</option>
                                        </Field>
                                        <ErrorMessage class="text-danger mt-1" name="civilite" />
                                        <label for="civilite">Choisissez une civilité</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-4">
                                    <label for="email" class="form-label">E-mail<span class="text-success font-weight-bold">*</span></label>
                                    <div class="form-floating">
                                        <!-- @input="validateEmail" -->
                                        <Field type="email" class="form-control" required id="fullemail" name="email" v-model="formData.email" :rules="validateEmail" />
                                        <ErrorMessage class="text-danger mt-1" name="email" />
                                        <label for="fullemail">E-mail</label>
                                    </div>
                                </div>

                                <div class="col-md-6 mb-4">
                                    <button type="submit" class="btn mt-5 border mx-5" style="background-color: #013f04; color: white">
                                        <span v-if="isLoading">Chargement...</span>

                                        <span v-else>S'inscrire</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                        <!-- <button type="submit" @click="showToastWarning('ceci est un test')" class="btn mt-5 border mx-5" style="background-color: #013f04; color: white">Toast</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            isLoading: false,
            formData: {
                fullName: "",
                fullPrenom: "",
                email: "",
                phoneNumber: "",
                password: "",
                password_confirmation: "",
                adresse: "",
                civilite: "",
                dateOfBirth: "",
            },
            errors: {
                fullName: null,
                fullPrenom: null,
                email: null,
                phoneNumber: null,
                password: null,
                password_confirmation: null,
                adresse: null,
                civilite: null,
                dateOfBirth: null,
            },
        };
    },
    created() {
        this.resetFormData();
    },
    methods: {
        resetFormData() {
            this.formData = {
                fullName: "",
                fullPrenom: "",
                email: "",
                phoneNumber: "",
                password: "",
                password_confirmation: "",
                adresse: "",
                civilite: "",
                dateOfBirth: "",
            };
        },

        validateNom(value) {
            if (!value) {
                return "Ce champs est requis";
            }
            if (value.length < 3) {
                return "Votre nom doit contenir au moin 3 caractères";
            }

            return true;
        },
        validateAdresse(value) {
            if (!value) {
                return "Ce champs est requis";
            }
            if (value.length > 255) {
                return "Votre adresse est trop longue";
            }

            return true;
        },
        validatePrenom(value) {
            if (!value) {
                return "Ce champs est requis";
            }
            if (value.length < 3) {
                return "Votre nom doit contenir au moin 3 caractères";
            }

            return true;
        },
        validatePhoneNumber(value) {
            if (!value) {
                return "Ce champs est requis";
            }

            var motif = /^\d{8}$/;

            if (!motif.test(value)) {
                return "Le numéro n'est pas valide";
            }

            return true;
        },
        validatePassword(value) {
            if (!value) {
                return "Ce champs est requis";
            }

            if (value) {
                if (value.length < 8) {
                    return "Mot de passe trop court ";
                }

                if (value.length > 30) {
                    return "Mot de passe trop long";
                }

                if (value.length > 8 && value.length < 30) {
                    if (this.formData.password_confirmation !== "") {
                        if (value !== this.formData.password_confirmation) {
                            return "Les mot de passe ne sont pas identiques";
                        }
                    }

                    return true;
                }
            }

            return true;
        },
        validatePasswordConfirmation(value) {
            if (!value) {
                return "Ce champs est requis";
            }

            if (value) {
                if (value.length < 8) {
                    return "Mot de passe trop court ";
                }

                if (value.length > 30) {
                    return "Mot de passe trop long";
                }

                if (value.length > 8 && value.length < 30) {
                    if (this.formData.password !== "") {
                        if (value !== this.formData.password) {
                            return "Les mot de passe ne sont pas identiques";
                        }
                    }
                }
            }

            return true;
        },
        validateCivilite(value) {
            if (!value) {
                return "Ce champs est requis";
            }
            return true;
        },
        validateDate(value) {
            // Vérifier le format de la date
            var formatValide = /^\d{4}-\d{2}-\d{2}$/.test(value);

            if (!formatValide) {
                return "Le format de la date n'est pas valide";
            }

            if (value) {
                // Convertir la date en objet Date
                var dateParts = value.split("-");
                var annee = parseInt(dateParts[0], 10);
                var mois = parseInt(dateParts[1], 10) - 1; // Les mois dans les objets Date sont 0-indexés
                var jour = parseInt(dateParts[2], 10);
                var dateSaisie = new Date(annee, mois, jour);

                // Obtenir la date d'aujourd'hui
                var dateAujourdhui = new Date();

                // Comparer la date saisie avec la date d'aujourd'hui

                if (dateSaisie > dateAujourdhui) {
                    return "La date est supérieur à la date d'aujourd'hui";
                }
            }

            return true;
        },
        validateEmail(value) {
            if (!value) {
                return "Ce champs est requis";
            }

            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return "Ce champ doit comporter un mail valide";
            }
            // All is good
            return true;
        },

        showToastWarning(message) {
            toast.warning(message, {
                autoClose: 2000,
            });
        },
        showToastSuccess(message) {
            toast.success(message, {
                autoClose: 2000,
            });
        },
        showToastInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },

        register(values) {
            this.isLoading = true;
            axiosInstance
                .post("api/register", this.formData)
                .then((response) => {
                    let statut = response.data.status;

                    if (statut == "422") {
                        const backendErrors = response.data.msg;
                        for (let el in backendErrors) {
                            this.showToastInfo(`${backendErrors[el][0]}`);
                        }
                        this.isLoading = false;
                    } else {
                        this.showToastSuccess(`${response.data.msg}`);
                        this.isLoading = false;

                        setTimeout(() => {
                            this.$router.push("/");
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.showToastWarning("Echec de l'inscription");
                });
        },
    },
};
</script>

<style scoped>
/* .contenue {
    margin-top: 200px;
} */
.vert {
    background-color: #013f04;
}
.bouton1 {
    border-radius: 5px;
    box-shadow: 20px 10px 50px black;
    font-family: bold;
}
.p1 {
    color: #013f04;
    font-family: Impact, sans-serif;
}
.compte {
    background-color: #f6f9ff;
}
.bouton2 {
    background-color: #013f04;
}
input {
    background-color: #f6f9ff;
}

.alert {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}
</style>
