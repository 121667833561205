import { createApp } from "vue";
import HomeView from "./views/HomeView.vue";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";


createApp(HomeView)
    .use(store)
    .use(router)

    .mount("#home");
