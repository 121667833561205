<template>

  <div class="container">
    <div class="row">
      <div class="  liste shadow shadow-primary ">
        <p class=" fs-5 para0 ">Edition Invité</p> 
          <p>Nom Evènement/Afficher invité/edition</p>
      </div>
    </div>
    
    <div class="row mt-4">
        <div class="user  shadow ">
             
            <div class="col-md-12 mt-2">
                 <form @submit.prevent="register">
                 <div class="row">
                 <div class="col-md-6 ">
                <label for="fullIdentite" class="form-label">Identité</label>
                <input type="text" id="fullIdentite" v-model="fullIdentite" required class="form-control"
                    placeholder="Identité">
            </div>
            <div class="col-md-6 ">
                <label for="phoneNumber" class="form-label">Numéro de téléphone</label>
                <input type="number" id="phoneNumber" v-model="phoneNumber" required class="form-control"
                    placeholder="Numero">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 ">
                <label for="email" class="form-label">E-mail</label>
                <input type="email" id="email" v-model="email" class="form-control" required placeholder="email">
            </div>
            <div class="col-md-6 ">
                <label for="civilité" class="form-label">Civilité</label>
                <select id="civilité" v-model="selectedCountry" class="form-select">
                    <option value="monsieur">Monsieur</option>
                    <option value="madame">Madame</option>
                    <option value="mademoiselle">Mademoiselle</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 ">
                <label for="date" class="form-label">Date Naissance</label>
                <input type="date" id="date" v-model="date" class="form-control" required
                    placeholder="date naissance">
            </div>
            <div class="col-md-6 ">
                <label for="fullName" class="form-label">Domaine Activité</label>
                <input type="text" id="fullName" v-model="fullName" class="form-control" required
                    placeholder="domaine">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 ">
                <label for="profession" class="form-label">Profession</label>
                <input type="text" id="profession" v-model="profession" class="form-control"
                    placeholder="Profession">
            </div>
            <div class="col-md-6 ">
                <label for="titre" class="form-label">Titre</label>
                <input type="text" id="titre" v-model="titre" class="form-control" placeholder="titre">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 ">
                <label for="fullmatrimoniale" class="form-label">Matrimoniale</label>
                <input type="text" id="fullmatrimoniale" v-model="fullmatrimoniale" required
                    class="form-control" placeholder="matrimoniale">
            </div>
            <div class="col-md-6 ">
                <label for="adresse" class="form-label">Adresse</label>
                <input type="text" id="adresse" v-model="adresse" class="form-control"
                    placeholder="adresse">
            </div>
        </div>

        <div class=" justify-content-center mt-2 mb-3">
            <button class="btn " style="background:rgba(1, 87, 1, 0.945); color:white;" type="submit">Edition</button>
        </div>
    </form>
</div>

           
    </div>
  </div>
     </div>
   
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
   
  },
};
</script>


<style scoped>

.liste{
  margin-top: 20px;
  width:103%;
  background-color: white;
  margin-right: 50px;

   
}
.para0{
 font-weight: bold;
 margin-bottom: 1px;
}

    
.user{
  background-color: white;
  margin-bottom:30px;
  border: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(7, 1, 1, 0);
  width:103%;
}

.bouton{
    color:rgba(1, 87, 1, 0.945);
    border-color:rgba(1, 87, 1, 0.945);
}
.bouton:hover{
background-color:rgba(1, 87, 1, 0.945);
color:white;
}
input{
    background-color:#f6f9ff;
}

select{
      background-color:#f6f9ff;
}
</style>
